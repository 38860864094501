/* eslint-disable react/button-has-type,no-unused-expressions,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react'
import * as PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {pad2} from "../../utils";
import {getCurrentDate} from "../../helpers/channels";
import ProgressBarHome from "../progressbar-home";
import Localised from "../localised";
import {getAssetTitle, trackAssetEvent} from '../../helpers/assets';
import fullscreenIcon from '../../assets/rlaxxTV_Icons_SVG_220114/icon/fullscreen/white.svg'
import rewind from '../../assets/rlaxxTV_Icons_SVG_220114/icon/rewind/white.svg'
import  forward from '../../assets/rlaxxTV_Icons_SVG_220114/icon/forward/white.svg'
import play from '../../assets/rlaxxTV_Icons_SVG_220114/icon/play/white.svg'
import pause from '../../assets/rlaxxTV_Icons_SVG_220114/icon/pause/white.svg'
import unmute from '../../assets/rlaxxTV_Icons_SVG_220114/icon/unmute/white.svg'
import mute from '../../assets/rlaxxTV_Icons_SVG_220114/icon/mute/white.svg'
import forwarding from '../../assets/rlaxxTV_Icons_SVG_220114/icon/forward-to-end/white.svg'
import windowed from "../../assets/rlaxxTV_Icons_SVG_220114/icon/windowed/white.svg"
import './style.scss'
import store from "../../storage/store";
import {PLAYER_LIVE_STATUS_CHANGE} from "../../views/player";
import PlayerContext from "../../storage/contexts/PlayerContext";
import CurrentVideoFollow from "../ad-counter-label/ad-label";
import {EVENTS} from "../../helpers/tracking";
import {isPortrait, mobileSize} from "../../helpers/measurements";




class HomeInfo extends React.Component{

  constructor(props) {
    super(props);
    const {isMuted}=this.props;
    this.state = {
      // eslint-disable-next-line react/no-unused-state
        active: false,
        interval: '',
        isLive: true,
        volumeLevel:isMuted?0:1,
        title:'',
        // fullscreen:false,
        progressBarScrolling:false
    };

    this.volumeRef=React.createRef();
    this.refPlayPauseIcon=React.createRef();
    this.refVolumeIcon=React.createRef();
    this.volumeProgress=React.createRef();
    this.refControlButtonRight=React.createRef();
    this.setVolume=this.setVolume.bind(this);
    this.toggleVolume=this.toggleVolume.bind(this);
    this.areChannelTags = false;


  }

  componentDidMount() {

    // const fullScreenElement =
    //   document.fullscreenElement ||
    //   document.msFullscreenElement ||
    //   document.mozFullScreenElement ||
    //   document.webkitFullscreenElement;
    //
    // // eslint-disable-next-line no-unneeded-ternary
    // this.setState({fullscreen:fullScreenElement?true:false})

      document.addEventListener(PLAYER_LIVE_STATUS_CHANGE, this.updateData());
      this.updateData()
      this.updateInterval = setInterval(() => {
          this.forceUpdate();
      }, 1000);


  }

  componentDidUpdate(prevProps, prevState) {
    const {
      id,
      liveChannelId,
      data,
      changeIsMuted,
      currentVideo,
      isAd
    } = this.props
    // const {isLive}=this.state;
    if (
      prevProps.id !== id ||
       prevProps.liveChannelId !== liveChannelId ||
      prevProps.currentVideo.startUnix !== currentVideo.startUnix ||
      prevProps.currentVideo.endUnix !== currentVideo.endUnix ||
      prevProps.currentVideo.content.id !== currentVideo.content.id||
      prevProps.data.id!==data.id
    ) {
      this.updateData();
    }
    // eslint-disable-next-line react/no-did-update-set-state
    if(prevProps.isAd!==isAd && prevProps.isAd===true && isAd===false ){
      // eslint-disable-next-line react/destructuring-assignment
      this.context.current.setLive(false)
      this.updateData()
    }
    const{volumeLevel}=this.state;

    if(prevState.volumeLevel!==volumeLevel){
      volumeLevel>0?changeIsMuted(false):changeIsMuted(true)
    }

  }

  componentWillUnmount() {
     clearInterval(this.updateInterval);
  }



  setVolume(){
    if(this.volumeProgress.current) {
      const {current}=this.context;
      const {min,max, value}=this.volumeProgress.current
      this.setState({volumeLevel: Number(value)}, () => {
        const {volumeLevel} = this.state
        current.setVolume(volumeLevel)
        this.volumeProgress.current.style.backgroundSize = `${(volumeLevel - min) * 100 / (max - min)  }% 100%`
        // this.refVolumeIcon.current.src=volumeLevel===0?mute:unmute;
      })
    }
  }

  isProgressBarScrolling=(bool)=>{
    // eslint-disable-next-line react/no-unused-state
    this.setState({progressBarScrolling:bool})
  }

  generateIntervalTime = (dataToShow) => {

      const startTime = new Date(dataToShow.startUnix * 1000);
      const endTime = new Date(dataToShow.endUnix * 1000);
      const startHhMm = `${pad2(startTime.getHours())}:${pad2(startTime.getMinutes())}`;
      const endHhMm = `${pad2(endTime.getHours())}:${pad2(endTime.getMinutes())}`;
      return {start:startHhMm,end:endHhMm}
  }


  checkLiveState = () => {
    const {current}=this.context;
      const { id, liveChannelId } = this.props;
    // eslint-disable-next-line eqeqeq
      if (id != liveChannelId) return true;
      if (current) return current.isLive;
      return true;
  };

  getCurrentTime = () => {
    const {current}=this.context;
      if (current) return current.getCurrentTime();
      return 0;
  };

  setCurrentTime = newVideoPosition => {
    const {current}=this.context;
      if (current) current.setCurrentTime(newVideoPosition);
  };

  play = () => {
    const {current}=this.context;
      if (current) {
          current.play();
          this.updateIsLive();
      }
  };

  pause = () => {
    const {current}=this.context;
      if (current) {
          current.pause();
          this.updateIsLive();
      }
  };

  toggleVideo = () => {
    const {current}=this.context;
      if (current) {
          current.toggleVideo();
        this.updateIsLive();
      }
  };



  forward=()=>{
      const{isLive}=this.state;
    const {current}=this.context;
    if(!isLive&&current) return current.forward('live');
     return null
  }



  // eslint-disable-next-line no-unused-vars
  updateIsLive = (checkFocus = false) => {
    const newLiveState = this.checkLiveState();
    this.setState({ isLive: newLiveState });
  };

  forwardLive = () => {
    const {current}=this.context;
    if(current) {
        current.forward('live', true);
        this.updateIsLive(true);
    }
    return null
  };

  updateData = () => {
    const{currentVideo,data, id, liveChannelId, timeShifting}=this.props;
    const assetToShow=Number(id)!==Number(liveChannelId)||!timeShifting?data:currentVideo;

    this.setState({
      interval: `${this.generateIntervalTime(assetToShow).start} - ${this.generateIntervalTime(assetToShow).end}`,
      isLive: this.checkLiveState(),
      title:getAssetTitle(assetToShow)
    });
  };

  fontSizeChanger = (text,expectedSize,originalFontSize=50.52 ) => {
    if (!text) return {};
    let fontSize = `${originalFontSize}px`;
    if (parseInt(text.length, 10) >= 88) {
      fontSize = `${expectedSize}px`;
    }
    return  fontSize ;
  };


  rewind(){
    const {current}=this.context;
    if(current) current.rewind();
    this.updateIsLive();
  }

  toggleVolume(){
      const {currentVideo}=this.props;
      const {current}=this.context;
      if(current) {
           if(current.isMuted()){
               const volume=current.getVolume();
               this.setState({volumeLevel:volume }, () => {
                   current.unmute()
                   trackAssetEvent(EVENTS.VIDEO.ACTIONS.UNMUTE,currentVideo)
                   if(this.volumeProgress.current) {
                       const {min,max,value} = this.volumeProgress.current
                       this.volumeProgress.current.style.backgroundSize = `${(value - min) * 100 / (max - min)}% 100%`
                   }
               })
           }else{

               this.setState({volumeLevel: 0}, () => {
                   current.mute();
                   trackAssetEvent(EVENTS.VIDEO.ACTIONS.MUTE,currentVideo)
                   if(this.volumeProgress.current) this.volumeProgress.current.style.backgroundSize = '0% 100%'
               })

           }
      }

  }



  renderTags() {
    const { channelTags, channelColor } = this.props;
    let langCode = store.getState().locales.language;

    if (!channelTags || !channelColor) {
      this.areChannelTags = false;
      return null;
    }
    if (!channelTags[langCode] || channelTags[langCode].length <= 0) {
      langCode = store.getState().app.countryCode;
      if (langCode === 'gb') langCode = 'en';
    }
    if (!channelTags[langCode] || channelTags[langCode].length <= 0) {
      this.areChannelTags = false;
      return null;
    }

    this.areChannelTags = true;

    return (
      <div>
      <ul className="home-info-channel-tags">
        {channelTags[langCode].slice(0, 3).map(item => (
          <li key={item.id} className="home-info-channel-tag-item">
            <div>
              <div className="bullet" style={{ backgroundColor: channelColor }} /> <div className="bullet overlay" />
            </div>
            {item.name}
          </li>
        ))}
      </ul>
      </div>
    );
  }

  renderAd=()=>
          <div id="ad-focus" className="home-info-ad">
              <Localised node="general.ads.will_continue" />
          </div>


  render(){
      const {  data, channelTitle,liveChannelId,id,screenSize,deviceType, isAd, currentVideo,infoRef,
        infoChannelPRef,infoChannelRef,infoContentPRef, infoContentRef, timeShifting,fullscreen, toggleFullScreen } = this.props;
      const { interval,volumeLevel,title, isLive, progressBarScrolling } = this.state;
      const time = getCurrentDate();
      return (
        <div
          ref={infoRef}
          id="home-info"
          className={`home-info ${(deviceType!=='desktop'||screenSize<mobileSize) ? "mobile" : "desktop"}
          ${deviceType==="tablet" && "tablet"} ${ (deviceType!=='desktop'||screenSize<mobileSize) && isPortrait()==='portrait' && "portrait"}`}

        >
          <div className={`info-wrapper  ${progressBarScrolling && "scrolling"}`}>
          <SwitchTransition mode="out-in">
            <CSSTransition key={id}
                           timeout={250}
                           classNames="home-info-transition">
          <div>
            <div  ref={infoChannelRef}  className="home-info-channel-title">
              {" "}
              <p   ref={infoChannelPRef} className="home-info-channel-title">
                {channelTitle}
              </p>{" "}
            </div>
            {deviceType==='desktop' && this.renderTags()}
          </div>
            </CSSTransition>
          </SwitchTransition>
          <SwitchTransition mode="out-in">
            <CSSTransition key={id}
                           timeout={250}
                           classNames="home-info-transition">
          <div ref={infoContentRef}
               className={`home-info-content-title ${progressBarScrolling && "scrolling"} ${deviceType==="tablet" && "tablet"}`} >
            <p
              ref={infoContentPRef}
              style={
                deviceType==='desktop' ? { fontSize: this.fontSizeChanger(title, 37.92) } : {}
              }
            >
              { isAd&&!timeShifting?getAssetTitle(currentVideo):title}
            </p>
          </div>
            </CSSTransition>
          </SwitchTransition>
          {(deviceType!=='desktop'||screenSize<mobileSize) && !isAd &&
          <div className={`timeline ${deviceType==="tablet" && "tablet"}`}>{interval}</div> }
          </div>
          {isAd ? (
            <div className={`home-info-progress-bar ${(deviceType!=='desktop'||screenSize<mobileSize) && isAd && "ads"}`}>
              <CurrentVideoFollow type={2} currentVideo={currentVideo} screenSize={screenSize} />
            </div>
          ) : (
            <div className="home-info-progress-bar">
              {  deviceType==='desktop'
              && <div className={`timeline  ${progressBarScrolling && "scrolling"}`} >{interval}</div> }
              <ProgressBarHome
                deviceType={deviceType}
                isLive={isLive}
                isProgressBarScrolling={this.isProgressBarScrolling}
                startTime={currentVideo.startUnix}
                startRealTime={data.startUnix}
                endRealTime={data.endUnix}
                videoTime={this.getCurrentTime()}
                currentTime={time.getTime() / 1000}
                endTime={currentVideo.endUnix}
                currentLiveChannel={liveChannelId}
                focusedChannelId={id}
                play={this.play}
                pause={this.pause}
                screenSize={screenSize}
                setCurrentTime={this.setCurrentTime}
                forwardLive={this.forwardLive}
                timeShifting={timeShifting}
              />
            </div>
          )}
          <div className={`home-info-control-bar ${deviceType==="tablet" && "tablet"}`}>
            {!isAd && timeShifting && (
              <div className="control-button-left">
                <div className="target-wrapper" role="button"     onClick={() => {
                  this.rewind();
                }}>

                <button
                  className="control-button"

                >
                  <img className="control-icon" src={rewind} alt="rewind" />
                </button>
                </div>
                <div className="target-wrapper" role="button"   onClick={() => {
                  this.toggleVideo();
                }}>
                <button
                  className="control-button"
                >
                  <img
                    ref={this.refPlayPauseIcon}
                    className="control-icon"

                    src={
                      // eslint-disable-next-line react/destructuring-assignment
                      this.context.current.isPaused?play:pause
                    }
                    alt="pause"
                  />
                </button>
                </div>
                <div className="target-wrapper" role="button"   onClick={() => {
                  this.forward();
                }}>
                <button
                  className={`control-button ${isLive && "passive"}`}

                  disabled={!!isLive}
                >
                  <img className="control-icon" src={forward} alt="forward" />
                </button>
                </div>
                {  deviceType==='desktop' && (
                  <button
                    className={`control-button ${isLive && "passive"}`}
                    onClick={() => {
                      this.forwardLive();
                    }}
                    disabled={!!isLive}
                  >
                    <img className="control-icon" src={forwarding} alt="forwarding" />
                  </button>
                )}
              </div>
            )}
            <div
              ref={this.refControlButtonRight}
              className={`control-button-right ${isAd && "inactive"}`}
            >
              <div className={`target-wrapper volume ${
                (deviceType!=='desktop'||screenSize<mobileSize) ? "first" : "last"
              }`} >
              <div
                ref={this.volumeRef}
                className={`volume-div ${volumeLevel !== 0 && "unmute"} `}
              >
                <button
                  className="control-button volume"
                  onClick={() => {
                    this.toggleVolume();
                  }}
                >
                  <img
                    className="control-icon"
                    ref={this.refVolumeIcon}
                    src={volumeLevel === 0 ? mute : unmute}
                    alt="mute"
                  />
                </button>
                {  deviceType==='desktop'  && (
                  <input
                    ref={this.volumeProgress}
                    onChange={() => {
                      this.setVolume();
                    }}
                    className="volume-input"
                    type="range"
                    min={0}
                    max={1}
                    value={volumeLevel}
                    step={0.02}
                  />
                )}
              </div>
              </div>
              <div className={`target-wrapper ${  (deviceType!=='desktop'||screenSize<mobileSize)  ? "last" : "first"}`}
                   role="button"    onClick={() => {
                toggleFullScreen();
              }}>
              <button
                id="fullScreen"
                className="control-button"
              >
                <img className="control-icon" src={fullscreen?windowed:fullscreenIcon} alt="fullscreen" />
              </button>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

HomeInfo.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    content: PropTypes.object,
    startUnix: PropTypes.number,
    endUnix: PropTypes.number
  }).isRequired,
  channelTitle: PropTypes.string,
  liveChannelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isAd: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentVideo:PropTypes.object,
  screenSize:PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  channelTags:PropTypes.object
};


HomeInfo.defaultProps = {
  channelTitle: undefined,
  liveChannelId: undefined,
  isAd: false,
  currentVideo:undefined,
  channelTags:undefined
};


HomeInfo.contextType = PlayerContext;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <HomeInfo infoRef={ref} {...props}/>);
