/* eslint-disable import/prefer-default-export,import/no-cycle */
// eslint-disable-next-line import/no-cycle
import {
  getSRAssetChannelTitle,
  getSRAssetContentPartnerName,
  getSRAssetDescription,
  getSRAssetDuration,
  getSRAssetMetadata,
  getSRAssetTitle,
  getSRThumbnail,
  getSRWatermark
} from './partners/sportradar';
import { parseAsLabel, trackVideoEvent} from './tracking';
import {  partnerIdentifiers } from './channels';
import contentApis from '../services/contentApis';

export const getAssetFromID = (assetId, partnerIdentifier) => {
  switch (partnerIdentifier) {
    case partnerIdentifiers.SPORTRADAR:
      return contentApis.sportradar.getAsset(assetId);
    default:
      return Promise.reject(`${partnerIdentifier} is not a valid Partner Identifier`);
  }
};


export const getAssetTitle = asset => asset?.title || getSRAssetTitle(asset);

export const getAssetMetadata = (asset, metaId) => asset?.[metaId] || getSRAssetMetadata(asset, metaId);

export const getAssetSubtitle = asset => asset?.subtitle || getAssetMetadata(asset, 'Season');

export const getAssetDescription = asset => asset?.description || getSRAssetDescription(asset);

export const getAssetChannelTitle = (asset) =>  asset?.channelTitle || getSRAssetChannelTitle(asset);

// eslint-disable-next-line import/no-mutable-exports

export const getAssetContentPartnerName = asset => asset?.contentPartnerName || getSRAssetContentPartnerName(asset);

export const getAssetThumbnail = (asset, manipulation) =>
  asset?.thumbnail || asset?.image || getSRThumbnail(asset, manipulation);

export const getAssetWatermark = asset => asset?.watermark || getSRWatermark(asset);

export const getAssetDuration = asset => asset?.duration || getSRAssetDuration(asset);


export const getCurrentAssetPlaybackType = () => {
  if (window.location.hash.indexOf('#/player') === -1) {
    return 'live';
  }
  return 'vod';
};




export const getAssetEventLabel = (asset, newPlaybackType=undefined) => {
  const getplaybackType=()=>{
    if(!newPlaybackType){
      return  getCurrentAssetPlaybackType() === 'vod' ? 'ondemand' : 'live';
    }

      return newPlaybackType;

  }


  const videoTitle = getAssetTitle(asset);
  const cpName = getAssetContentPartnerName(asset);
  const channelTitle = getAssetChannelTitle(asset);
  const playbackType = getplaybackType(newPlaybackType)

  return parseAsLabel(cpName, channelTitle, videoTitle, playbackType);
};

export const trackAssetEvent = (eventAction, asset, playbackType=undefined) => {
  trackVideoEvent(eventAction, getAssetEventLabel(asset, playbackType));

};





