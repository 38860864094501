/* eslint-disable */

import { isWithinInterval } from 'date-fns';
import {
  getCurrentDate,
  getPartnerIdentifierFromAsset,
  getPartnerIdentifierFromChannel,
  partnerIdentifiers
} from './channels';
import { getSRStream } from './partners/sportradar';
import { getVevoStream } from './partners/vevo';
import { getPantaflixStream } from './partners/pantaflix';

export const isGoingToPlay = (video, clockTime = getCurrentDate()) => {
  if (!video) return false;
  const start = new Date(video.start);
  const end = new Date(video.end);

  return isWithinInterval(clockTime, { start, end });
};

export const isCurrentlyPlaying = video => isGoingToPlay(video, getCurrentDate());

export const getPlayingMediaPositionFromChannel = (epg, epgId, clockTime) => {
  if (epg[epgId] && epg[epgId].assets) {
    for (let i = 0; i < epg[epgId].assets.length; i++) {
      const asset = epg[epgId].assets[i];
      if (isCurrentlyPlaying(asset, clockTime)) {
        return i;
      }
    }
  }
  return -1;
};

/**
 * Retrieve playing element for a specified time
 * @param epg Array with all the EPG Items
 * @param epgId Channel ID to look for
 * @param clockTime Time in which the element shall be playing (Default: current time)
 * @returns {*}
 */
export const getPlayingVideoFromChannel = (epg, epgId, clockTime) =>
  new Promise((resolve, reject) => {
    if (epg[epgId] && epg[epgId].assets) {

      const mediaPos = getPlayingMediaPositionFromChannel(epg, epgId, clockTime);
      if (mediaPos !== -1) {
        resolve(epg[epgId].assets[mediaPos]);
      } else {
        reject(
          `No element playing for channel ${epgId} (${epg[epgId]?.displayName}) at ${(
            clockTime || getCurrentDate()
          ).toLocaleString()}.`
        );
      }
    } else {
      reject(`Channel ${epgId} not found.`);
    }
  });

export const getStreamVideoFromChannel = (epg, epgId) =>
  getPlayingVideoFromChannel(epg, epgId).then(asset => getStreamableAsset(asset));

export const appendObjectToAsset = (asset, object) => Object.assign({}, asset, object);

export const standardizeStreamObject = (source, mimeType) => ({ stream: { source, mimeType } });

export const standardizeVastObject = url => {
  // https://pubads.g.doubleclick.net/gampad/ads?iu=/33100160/RLAXXTV/nyx_nyxchannel&sz=640x360&description_url=[PAGE_URL]&env=vp&gdfp_req=1&output=vast&unviewed_position_start=1&cust_params=videoposition%3Dpreroll1&url=&correlator=
  const vastUrl = url
    .replace('[PAGE_URL]', '%DESCRIPTION_URL%')
    .replace('preroll1', '%AD_TYPE%')
    .replace('&url=', '&url=%URL%')
    .replace('&correlator=', '&correlator=%CORRELATOR%');
  return { vastUrl };
};

export function getStreamableAsset(asset) {
  const partnerIdentifier = getPartnerIdentifierFromAsset(asset);
  switch (partnerIdentifier) {
    case partnerIdentifiers.VEVO:
      return getVevoStream(asset);
    case partnerIdentifiers.PANTAFLIX:
      return getPantaflixStream(asset);
    case partnerIdentifiers.SPORTRADAR:
    default:
      return getSRStream(asset);
  }
}

export function getEPGIdFromChannel(channel) {
  const partnerIdentifier = getPartnerIdentifierFromChannel(channel);
  switch (partnerIdentifier) {
    case partnerIdentifiers.VEVO:
      return channel.externalData.Vevo.apiData.epg.slug;
    case partnerIdentifiers.PANTAFLIX:
      return channel.id;
    case partnerIdentifiers.SPORTRADAR:
      return channel.externalData.SportRadar.apiData.epg.channel_guid;
    default:
      return -1;
  }
}

export function getCategoryIDFromChannel(channel) {
  if (
    channel &&
    channel.externalData &&
    channel.externalData.SportRadar &&
    channel.externalData.SportRadar.apiData &&
    channel.externalData.SportRadar.apiData.categories &&
    channel.externalData.SportRadar.apiData.categories.length > 0
  ) {
    return channel.externalData.SportRadar.apiData.categories[0].category_id;
  }
  return -1;
}

export function liveVideoTotalProgress(startUnix) {
  const time = getCurrentDate();

  const current = time.getTime() / 1000;
  return current - startUnix;
}

/**
 *
 * @param {String} text
 * @param {Number} originalFontSize
 * @returns {String} className
 */
export const getTitleSizeClassname = (text, originalFontSize) => {
  if (!text) return undefined;
  const textLength = parseInt(text.length, 10);
  let className = 'title--size-xxl';
  if (textLength >= 57) {
    className = 'title--size-s';
  } else if (textLength >= 52) {
    className = 'title--size-m';
  } else if (textLength >= 47) {
    className = 'title--size-l';
  } else if (textLength >= 35) {
    className = 'title--size-xl';
  }
  return className;
};

/**
 *
 * @param timeInSeconds
 * @returns {string}
 */
export const parseTime = timeInSeconds => {
  if (!timeInSeconds) return '00:00';
  const hours = Math.floor(timeInSeconds / (60 * 60));
  const minutes = Math.floor((timeInSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(timeInSeconds) % 60;
  if (isNaN(seconds)) return '00:00';

  let parsed = [minutes, seconds].map(pad2);
  if (hours) parsed = [hours, ...parsed];
  return parsed.join(':');
};

const pad2 = number => (number < 10 ? '0' : '') + number;

export default {
  isCurrentlyPlaying,
  getPlayingVideoFromChannel,
  getStreamVideoFromChannel,
  getStreamableAsset,
  liveVideoTotalProgress,
  getEPGIdFromChannel,
  getCategoryIDFromChannel,
  getPlayingMediaPositionFromChannel,
  getTitleSizeClassname,
  parseTime
};
