/* eslint-disable import/prefer-default-export,import/no-cycle,no-plusplus,no-throw-literal */

import CONSTANTS from './constants';
import { video } from '../../../helpers';
import  { playerActions } from '../../store';
import { getAssetFromID, trackAssetEvent } from '../../../helpers/assets';
import {  EVENTS } from '../../../helpers/tracking';
import { getCategoryIDFromChannel } from '../../../helpers/video';
import {  getParsedEpgId, getPartnerIdentifierFromChannel } from '../../../helpers/channels';

export function callAction(action, newState) {
  return {
    type: action,
    payload: newState
  };
}
export function getScreenSize(data){
    return callAction(CONSTANTS.GET_SCREENSIZE,data)
}


export function  toggleTabBar(){
    return callAction(CONSTANTS.TOGGLE_TABBAR_VISIBLE)
}

export function updateRotation(data){
  return callAction(CONSTANTS.UPDATE_ROTATION,data)
}

export function getDeviceType(data){
  return callAction(CONSTANTS.GET_DEVICE_TYPE,data)
}

export function getBurgerMenuExtended(data){
  return callAction(CONSTANTS.GET_BURGER_MENU_EXTENDED, data)
}

export function updateLiveChannelsData(data) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.UPDATE_CHANNELS, { type: 'live', data })));
    });
}



export function updatePromotedData(data) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.UPDATE_PROMOTED, data)));
    });
}

export function updateVODChannelsData(data) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.UPDATE_CHANNELS, { type: 'vod', data })));
    });
}

export function updateEpgData(data) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.UPDATE_EPG, data)));
    });
}

export function updateVODData(data) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.UPDATE_VOD, data)));
    });
}

export function setCountry(countryCode) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.SET_COUNTRY, countryCode?.toLowerCase())));
    });
}

export function getAssetFromEPGChannel(epgId, assetId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { epg } = getState().app;
      if (epg[epgId] && epg[epgId].assets) {
        for (let i = 0; i < epg[epgId].assets.length; i++) {
          const asset = epg[epgId].assets[i];
          if (asset.id === assetId) {
            resolve(asset);
            return;
          }
        }
        reject(`Asset ${assetId} not found in epg channel ${epgId}.`);
      } else {
        reject(`EPG Channel ${epgId} not found or doesn't have assets.`);
      }
    });
}

export function getChannelFromEPGID(epgId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { epg } = getState().app;
      if (epg[epgId] && epg[epgId].channelInfo) {
        resolve(epg[epgId].channelInfo);
      } else {
        reject(`Channel with EPGID ${epgId} not found.`);
      }
    });
}

export function getChannelFromID(channelId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { vodChannels } = getState().app;
      for (let i = 0; i < vodChannels.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (vodChannels[i].id == channelId) {
          resolve(vodChannels[i]);
          return;
        }
      }
      reject(`Channel ${channelId} not found.`);
    });
}

export function getChannelFromSRCat2(cat2) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { vodChannels } = getState().app;
      for (let i = 0; i < vodChannels.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (vodChannels[i].sr_cat_2 && (vodChannels[i].sr_cat_2 == cat2 || vodChannels[i].sr_cat_2[0] == cat2)) {

          resolve(vodChannels[i]);
          return;
        }
      }
      reject(`Channel with cat2 ${cat2} not found.`);
    });
}

function findVodAsset(vodCategoryData, assetId) {
  for (let i = 0; i < vodCategoryData.length; i++) {
    if (vodCategoryData[i].id === assetId) {
      return vodCategoryData[i];
    }
  }
  return null;
}

export function getAssetFromChannel(channelId, assetId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) =>
      dispatch(getChannelFromID(channelId))
        .then(channel => {
          const catId = getCategoryIDFromChannel(channel);
          if (catId === -1) {
            throw ` Channel ${channelId} was not found as a VOD Channel.`;
          }

          const { vod } = getState().app;
          const vodAsset = findVodAsset(vod[catId].data, assetId);

          if (vodAsset) {
            resolve(vodAsset);
          }

          const partnerIdentifier = getPartnerIdentifierFromChannel(channel);
          if (partnerIdentifier) {
            getAssetFromID(assetId, partnerIdentifier)
              .then(response => {
                if (response.data.data) {
                  resolve(response.data?.data);
                } else {
                  throw new Error();
                }
              })
              .catch(() => {
                throw `Asset ${assetId} not found in channel ${channelId}`;
              });
          } else {
            throw `Asset ${assetId} not found in channel ${channelId}`;
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);

          reject(error);
        })
    );
}

export function getPlayingVideoFromChannel(epgId) {
  return (dispatch, getState) => {
    const { epg } = getState().app;
    return video.getPlayingVideoFromChannel(epg, epgId);
  };
}

export function updateCurrentPlayingVideoFromChannel(
  epgId,
  forceUpdate = false,
  callback = undefined,
  replayIfSame = false
) {

  return (dispatch, getState) => {
    const { epg, currentChannel } = getState().app;
    const { currentVideo } = getState().player;
    // eslint-disable-next-line eqeqeq
    if (!forceUpdate && currentChannel.epgId == epgId) return Promise.resolve();
    const parsedEpgId = getParsedEpgId(epgId);
    return video
      .getStreamVideoFromChannel(epg, parsedEpgId)
      .then(assetToPlay => {
        // eslint-disable-next-line eqeqeq
        if (!currentVideo || replayIfSame || currentVideo.id != assetToPlay.id) {
          const { id } = epg[epgId].channelInfo;
          dispatch(callAction(CONSTANTS.SET_CURRENT_CHANNEL, { epgId, id }));
          dispatch(playerActions.setPlayingVideo(assetToPlay));
          trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL, assetToPlay);
        }

        if (typeof callback === 'function') callback(assetToPlay);
        return assetToPlay;
      })
      .catch(e => {
        if (typeof e === 'string') {
          // eslint-disable-next-line no-console
        console.log(e)
      }})
  };
}
