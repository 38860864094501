import React,{Suspense} from 'react';
import {Router, Route, Switch, withRouter, Redirect} from 'react-router';
// import {CSSTransition, TransitionGroup} from "react-transition-group";
import OnBoarding from "../views/on-boarding";
import ErrorPage from '../views/error';
import history from './history';
import Home from "../views/home";
import LazyLoading from "../views/lazy-loading";
import ProgramGuideView from "../views/program-guide-view";
import OnDemandPage from "../views/on-demand";
import OnDemandChannels from "../views/on-demand-channels";
import ChannelPage from "../views/channel-page";
import Settings from "../views/settings";
import PlayerControllers from '../views/player/playerControllers';
import appProperties from "./app-properties";
import BetaGate from "../views/beta-gate";
import Promoted from "../views/promoted";


const handler = {
  isAnimating: false
};


const Routing = () => (
  <Router history={history}>
    <Suspense fallback={<LazyLoading />}>
      <RoutesWithRouter/>
    </Suspense>
  </Router>
);
// eslint-disable-next-line react/prefer-stateless-function
class Routes extends React.Component{
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    handler.isAnimating = false;
  }


  shouldComponentUpdate(nextProps) {
    return (
      // eslint-disable-next-line react/destructuring-assignment
      this.props.location.pathname !== nextProps.location.pathname
    );
  }

  onCSSTransitionEnter = () => {
    handler.isAnimating = true;
  };

  onCSSTransitionExited = () => {
    handler.isAnimating = false;
  };


  render(){
      const{location}=this.props;
        return(
          // <TransitionGroup>
          //   <CSSTransition
          //     key={location.pathname}
          //     in
          //     timeout={250}
          //
          //     classNames="fade-transition"
          //     onEnter={this.onCSSTransitionEnter}
          //     onExited={this.onCSSTransitionExited}>
          <Switch location={location}>
                        <Route exact path="/beta-gate" component={BetaGate}/>
                        {/* <Route exact path="/:assetType?/:partnerId?/:assetId?" component={Home} /> */}
                        <Route exact path="/">
                          {appProperties.gdprAccepted?<Redirect to="/home"/>:<OnBoarding/>}
                        </Route>
                        <Route exact path="/home/:partnerId?" component={Home} />
                        <Route exact path="/home/:assetType?/:epgId?/:assetId?" component={Home} />
                        <Route exact path="/home/:partnerId?/:assetType?/:epgId?/:assetId?" component={Home} />
                        <Route path="/program-guide/:assetType?/:epgId?/:assetId?" component={ProgramGuideView} />
                        <Route path="/program-guide" component={ProgramGuideView} />
                        <Route path="/on-demand/vod/:contentId/:asset?/:assetId?" component={OnDemandChannels} />
                        <Route path="/on-demand/channel-page/:page_id/:asset?/:assetId?" component={ChannelPage} />
                        <Route path="/on-demand/:asset?/:assetId?" component={OnDemandPage} />
                        <Route path="/settings/:activeTab" component={Settings} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/player" component={PlayerControllers}/>
                        <Route path="/promoted/:step(overview|channels)?/:count?" component={Promoted} />
                        <Route path="/start" component={OnBoarding} />
                        <Route path="/error/:code?/:type?" component={ErrorPage} />
                        {/* Not Found Routing */}
                        <Route path="*" component={ErrorPage} />
                    </Switch>
          // </CSSTransition>
          // </TransitionGroup>
            )
    }
}


const RoutesWithRouter = withRouter(Routes);

export { Routing as default, handler as properties };
