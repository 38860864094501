/* eslint-disable react/button-has-type,
jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,
jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import './style.scss'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";
import DeviceDetector from "device-detector-js";
import Lottie from "react-lottie";
import rlaxxLogo from '../../../assets/images/logo.svg'
import back from '../../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg'
import history from "../../../storage/history";
import PlayerContext from "../../../storage/contexts/PlayerContext";
import {initLiveAds, initVODAds, stopLiveAds} from "../../../helpers/ads/ads";
import ProgressBarVod from "../../../components/progressbar-vod";
import {getAssetTitle} from "../../../helpers/assets";
import mute from "../../../assets/rlaxxTV_Icons_SVG_220114/icon/mute/white.svg";
import fullscreenIcon from "../../../assets/rlaxxTV_Icons_SVG_220114/icon/fullscreen/white.svg";
import unmute from "../../../assets/rlaxxTV_Icons_SVG_220114/icon/unmute/white.svg";
import rewind from '../../../assets/rlaxxTV_Icons_SVG_220114/icon/rewind/white.svg';
import pause from '../../../assets/rlaxxTV_Icons_SVG_220114/icon/pause/white.svg';
import play from '../../../assets/rlaxxTV_Icons_SVG_220114/icon/play/white.svg';
import forward from '../../../assets/rlaxxTV_Icons_SVG_220114/icon/forward/white.svg'
import windowed from '../../../assets/rlaxxTV_Icons_SVG_220114/icon/windowed/white.svg'
import AdCounterRing from "../../../components/ad-counter-label/ad-counter";
import CurrentVideoFollow from "../../../components/ad-counter-label/ad-label";
import RotateWarning from "../../rotate-warning";
import {EVENTS, trackVideoEvent} from "../../../helpers/tracking";
import {DISPLAY_HIDE_UI_DURATION} from "../../../helpers/measurements";
import Localised from "../../../components/localised";
import spinnerData from "../../../helpers/spinner.json";
import store, {appActions} from "../../../storage/store";
import {video} from "../../../helpers";


const deviceDetector = new DeviceDetector();
const {userAgent} = window.navigator;
const device = deviceDetector.parse(userAgent);

class PlayerControllers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
      duration: 0,
      volumeLevel: 1,
      UIActive: true,
      isPlay: true,
      fullscreen: false,
      fadedPlay: false,
      fadedRewind: false,
      fadedForward: false,
      pressed: false
    };
    this.spinnerOptions = {
      loop: true,
      autoplay: true,
      animationData: spinnerData,
    };
    this.playerRef = React.createRef();
    this.refMobileBack = React.createRef();
    this.refVolumeButton = React.createRef();
    this.refMobileBackIcon = React.createRef();
    this.refMobileRewind = React.createRef();
    this.refMobileRewindIcon = React.createRef();
    this.refMobilePlayPause = React.createRef();
    this.refMobileForward = React.createRef();
    this.refMobileForwardIcon = React.createRef();
    this.refDesktopBack = React.createRef();
    this.refDesktopBackIcon = React.createRef();
    this.refDesktopFullscreen = React.createRef();
    this.refDesktopFullscreenIcon = React.createRef();
    this.refDesktopPlayPause = React.createRef();
    this.refDesktopRewind = React.createRef();
    this.refDesktopRewindIcon = React.createRef();
    this.refDesktopForward = React.createRef();
    this.refDesktopForwardIcon = React.createRef();
    this.refControllerHeader = React.createRef();
    this.volumeRef = React.createRef();
    this.refPlayPauseIcon = React.createRef();
    this.refVolumeIcon = React.createRef();
    this.volumeProgress = React.createRef();
    this.refProgressbar = React.createRef();
    this.refButtonGroup = React.createRef();
    this.setVolume = this.setVolume.bind(this);
    this.toggleVolume = this.toggleVolume.bind(this);
    this.fullScreen = this.fullScreen.bind(this);
    this.displayHideUI = this.displayHideUI.bind(this);

    this.timeToChange = parseInt(process.env.REACT_APP_PLAYER_TIME_TO_CHANGE_LIVE, 10) || 15;
  }

  componentDidMount() {
    const {deviceType} = this.props;
    const {current} = this.context;
    this.initTimeTicker();
    stopLiveAds();
    initVODAds();
    this.displayHideUI();
    // if (deviceType !== "desktop" && isPortrait() === "landscape" && this.checkFullScreen() === false) {
    //   this.fullScreen();
    // }
    if (deviceType !== "desktop") this.fullScreen();

    if (this.checkFullScreen()) {
      this.setState({fullscreen: true});
    }
    if (current) {
      current.unmute();
      this.setState({volumeLevel: current.getVolume()});
    }


    document.addEventListener("keydown", () => {
      const {UIActive} = this.state;
      if (UIActive) {
        this.hideUI();
      } else {
        this.displayHideUI();
      }
    });
    document.addEventListener("mouseleave", () => {
      const {UIActive} = this.state;
      if (UIActive) this.hideUI();
    });
    document.addEventListener("mouseenter", () => {
      const {UIActive} = this.state;
      if (!UIActive) this.displayHideUI();
    });
    document.addEventListener("mousemove", () => {
      clearTimeout(this.hideTo);
      clearTimeout(this.displayUITO);

      this.displayHideUI();
    });

    // eslint-disable-next-line no-unused-vars
    document.addEventListener("mousedown", (e) => {
      const {UIActive} = this.state;
      if (UIActive && this.checkClickedElement(e) === true) {
        this.hideUI();
      } else if (UIActive && this.checkClickedElement(e) === false) {
        this.displayHideUI();
      } else {
        this.displayHideUI();
      }
    });
  }

  // componentDidUpdate(prevProps) {
  //   const{isAd} =this.props;
  //   if(prevProps.isAd===true && isAd===false){
  //     this.initTimeTicker()
  //   }
  // }

  componentWillUnmount() {
    const {deviceType}=this.props;
    this.checkContinueWatching();
    this.getOriginalVideoFromChannel()
    clearInterval(this.timeInterval);
    clearTimeout(this.hideTo);
    clearTimeout(this.displayUITO);

    document.removeEventListener("keydown", this.displayHideUI);
    document.removeEventListener("mousemove", this.displayHideUI);
    document.removeEventListener("mousedown", this.displayHideUI);
    if (deviceType !== "desktop") this.cancelFullScreen();

  }


  get duration() {
    const {current} = this.context;
    if (current) return current.getDuration();
    return undefined;
  }

  get currentTime() {
    const {current} = this.context;
    if (current) return current.getCurrentTime();
    return 0;
  }

  set currentTime(newVideoPosition) {
    const {current} = this.context;
    if (current) current.setCurrentTime(newVideoPosition);
  }

  get adsEnabled() {
    const {location} = this.props;
    if (!location.state) return true;
    return location.state.adsEnabled;
  }

  get isAd() {
    // eslint-disable-next-line react/prop-types
    const {isAd} = this.props;
    return isAd || false;
  }

  setProgress = (newCurrentTime) => {
    // const {current}=this.context;
    this.displayHideUI();
    this.currentTime = newCurrentTime;
    this.setState({currentTime: newCurrentTime, isPlay: true});
  };

  setVolume() {
    if (this.volumeProgress.current) {
      this.displayHideUI();
      const {value, min, max} = this.volumeProgress.current;
      this.setState({volumeLevel: Number(value)}, () => {
        const {current} = this.context;
        const {volumeLevel} = this.state;
        current.setVolume(volumeLevel);
        this.volumeProgress.current.style.backgroundSize = `${
          ((volumeLevel - min) * 100) / (max - min)
        }% 100%`;
      });
    }
  }


  toggleVideo = () => {
    const {current} = this.context;
    if (current && !this.isAd) {
      current.toggleVideo();
      this.setState({isPlay: !current.isPaused});
    }
  };

  forward = () => {
    const {current} = this.context;
    const {currentTime} = this.state;
    if (current && !this.isAd) {
      const newCurrentTime = currentTime + this.timeToChange;
      this.setState(
        {
          currentTime: newCurrentTime < this.duration ? newCurrentTime : this.duration,
          isPlay: true,
        },
        () => {
          current.forward(this.timeToChange);
        }
      );
    }
  };

  fadedOutPlay = (cond) => {
    if (cond === false) {
      this.setTo = setTimeout(() => {
        this.setState({fadedPlay: cond}, () => {
          clearTimeout(this.setTo);
        })
      }, 150)

    } else {
      this.setState({fadedPlay: cond})
    }
  }

  fadedOutRewind = (cond) => {
    if (cond === false) {
      this.setTo = setTimeout(() => {
        this.setState({fadedRewind: cond}, () => {
          clearTimeout(this.setTo);
        })
      }, 150)

    } else {
      this.setState({fadedRewind: cond})
    }
  }

  fadedOutForward = (cond) => {
    if (cond === false) {
      this.setTo = setTimeout(() => {
        this.setState({fadedForward: cond}, () => {
          clearTimeout(this.setTo);
        })
      }, 150)

    } else {
      this.setState({fadedForward: cond})
    }
  }

  buttonPressed = (cond) => {
    this.setState({pressed: cond})
  }


  rewind = () => {
    const {current} = this.context;
    const {currentTime} = this.state;
    if (current && !this.isAd) {
      const newCurrentTime = currentTime - this.timeToChange;
      this.setState({currentTime: newCurrentTime > 0 ? newCurrentTime : 0, isPlay: true}, () => {
        current.rewind(this.timeToChange);
      });
    }
  };

  initTimeTicker = () => {
    // const { isAd } = this.props;
    // if (isAd) return;
    clearInterval(this.timeInterval);
    this.setState({currentTime: this.currentTime, duration: this.duration});
    this.timeInterval = setInterval(() => {
      this.setState({currentTime: this.currentTime, duration: this.duration});
    }, 200);
  };

  onCSSTransitionEnter = () => {
    if (this.playerRef.current) this.playerRef.current.style.display = "block";
  };

  onCSSTransitionExited = () => {
    if (this.playerRef.current) this.playerRef.current.style.display = "none";
  };

  checkContinueWatching = () => {
    if(sessionStorage.getItem('ad_continue_watching') !== 'true') {
      const { currentTime, duration } = this.state;
      const {currentVideo}=this.props;
      const parsedVideo = currentVideo;
      let savedWatching = JSON.parse(localStorage.getItem('continue_watching'));
      if (!savedWatching) savedWatching = [];
      const id =  parsedVideo?.id ;
     // const id =  parsedVideo?.content?.event?.id || parsedVideo?.event?.id ;
      const index = savedWatching.findIndex(asset => Number(asset.id) === Number(id));
      if (currentTime > 20 && currentTime < duration - 60) {
        parsedVideo.savedTime = Math.floor(currentTime);
        if (index !== -1) savedWatching[index] = parsedVideo;
        // add item at first position while length is less than 100
        else if (savedWatching.length < 100) savedWatching.unshift(parsedVideo);
        else {
          // limit continueWatching to 100 items
          savedWatching.pop();
          savedWatching.unshift(parsedVideo);
        }
      } else if (currentTime > duration - 60 && index !== -1) {
        savedWatching.splice(index, 1); // remove item watched

        // todo add logic to add next episode if is a serie
      }
      localStorage.setItem('continue_watching', JSON.stringify(savedWatching));
    }
    sessionStorage.removeItem('ad_continue_watching');
  };



  getOriginalVideoFromChannel = () => {
    // eslint-disable-next-line react/prop-types
    const { currentLiveChannel } = this.props;
    const {current} = this.context;
    store.dispatch(
      appActions.updateCurrentPlayingVideoFromChannel(currentLiveChannel.epgId, true, playingAsset => {
        initLiveAds();
        current.setCurrentTime(video.liveVideoTotalProgress(playingAsset.startUnix));
      })
    );

  };



  hideUI() {
    const {UIActive} = this.state;
    if (UIActive) {
      if (this.hideTo) clearTimeout(this.hideTo);
      if (this.hideTo) clearTimeout(this.displayUITO);
      this.hideUITo = setTimeout(() => {
        this.setState({UIActive: false});
      }, 10);
    }
  }

  checkClickedElement(e) {
    if (
      (this.refMobileBack.current && e.target === this.refMobileBack.current) ||
      (this.refMobileBackIcon.current && e.target === this.refMobileBackIcon.current) ||
      (this.refMobileRewind.current && e.target === this.refMobileRewind.current) ||
      (this.refMobileRewindIcon.current && e.target === this.refMobileRewindIcon.current) ||
      (this.refMobilePlayPause.current && e.target === this.refMobilePlayPause.current) ||
      (this.refMobileForward.current && e.target === this.refMobileForward.current) ||
      (this.refMobileForwardIcon.current && e.target === this.refMobileForwardIcon.current) ||
      (this.refDesktopBack.current && e.target === this.refDesktopBack.current) ||
      (this.refDesktopBackIcon.current && e.target === this.refDesktopBackIcon.current) ||
      (this.refDesktopFullscreen.current && e.target === this.refDesktopFullscreen.current) ||
      (this.refDesktopFullscreenIcon.current &&
        e.target === this.refDesktopFullscreenIcon.current) ||
      (this.refDesktopPlayPause.current && e.target === this.refDesktopPlayPause.current) ||
      (this.refDesktopRewind.current && e.target === this.refDesktopRewind.current) ||
      (this.refDesktopRewindIcon.current && e.target === this.refDesktopRewindIcon.current) ||
      (this.refDesktopForward.current && e.target === this.refDesktopForward.current) ||
      (this.refDesktopForwardIcon.current && e.target === this.refDesktopForwardIcon.current) ||
      (this.volumeRef.current && e.target === this.volumeRef.current) ||
      (this.refPlayPauseIcon.current && e.target === this.refPlayPauseIcon.current) ||
      (this.refVolumeIcon.current && e.target === this.refVolumeIcon.current) ||
      (this.volumeProgress.current && e.target === this.volumeProgress.current) ||
      (this.refProgressbar.current && e.target === this.refProgressbar.current) ||
      (this.refVolumeButton.current && e.target === this.refVolumeButton.current)
    ) {
      return false;
    }
    return true;
  }

  displayHideUI() {
    if (this.hideTo) clearTimeout(this.hideTo);
    if (this.displayUITO) clearTimeout(this.displayUITO);
    this.displayUITO = setTimeout(() => {
      this.setState({UIActive: true}, () => {
        this.hideTo = setTimeout(() => {
          this.setState({UIActive: false});
        }, DISPLAY_HIDE_UI_DURATION);
      });
    }, 10);

    if (this.playerRef.current) this.playerRef.current.zIndex = 200;
  }




  fullScreen(){
    const {currentVideo}=this.props;
    const app=document.getElementById('root');
    if (app.requestFullScreen) {
      app.requestFullScreen();
    }else if (app.webkitEnterFullscreen) {
      app.webkitEnterFullscreen(); // for iphone this code worked
    } else if (app.webkitRequestFullScreen) {
      app.webkitRequestFullScreen();
    }
    else if (window.webkitRequestFullscreen) {
      window.webkitRequestFullscreen();
    }else if (app.webkitRequestFullscreen) {
      app.webkitRequestFullscreen();
    }  else if (app.mozRequestFullScreen) {
      app.mozRequestFullScreen();
    } else if (app.msRequestFullscreen) {
      app.msRequestFullscreen();
    }

    trackVideoEvent(EVENTS.VIDEO.ACTIONS.FULLSCREEN,currentVideo)

  }

  cancelFullScreen() {
    const {currentVideo} = this.props;
    if(document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }else if(document.mozCancelFullScreen){
        document.mozCancelFullScreen();
      } else {
        document.requestFullScreen();
      }
    }


      trackVideoEvent(EVENTS.VIDEO.ACTIONS.CANCEL_FULLSCREEN, currentVideo);
      // eslint-disable-next-line import/no-named-as-default-member

    if (history.location.pathname.indexOf('/asset') === -1) {
      console.log(history.location.pathname, 'path');
      // eslint-disable-next-line import/no-named-as-default-member
      history.goBack();
    }
  }

  toggleFullScreen() {
    const {currentVideo} = this.props;
    if (
      document.fullscreenElement ||
      document.msFullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    ) {
      this.setState({fullscreen: false}, () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }else if(document.mozCancelFullScreen){
          document.mozCancelFullScreen();
        }else {
          document.requestFullScreen();
        }
        trackVideoEvent(EVENTS.VIDEO.ACTIONS.CANCEL_FULLSCREEN, currentVideo);
      });
    }

    if (
      !document.fullscreenElement &&
      !document.msFullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      this.setState({fullscreen: true}, () => {
        this.fullScreen();
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkFullScreen() {
    if (
      !document.fullscreenElement &&
      !document.msFullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ){
      return false;

    }

    return true;
  }

  toggleVolume() {
    const {currentVideo} = this.props;
    const {current} = this.context;
    if (current) {
      if (current.isMuted()) {
        const volume = current.getVolume();
        this.setState({volumeLevel: volume}, () => {
          current.unmute();
          if (this.refVolumeIcon.current) this.refVolumeIcon.current.src = unmute;
          if (this.volumeProgress.current) {
            const {max, min} = this.volumeProgress.current;
            this.volumeProgress.current.value = volume;
            this.volumeProgress.current.style.backgroundSize = `${
              ((volume - min) * 100) / (max - min)
            }% 100%`;
          }
        });
        trackVideoEvent(EVENTS.VIDEO.ACTIONS.UNMUTE, currentVideo);
      } else {
        this.setState({volumeLevel: 0}, () => {
          current.mute();
          if (this.refVolumeIcon.current) this.refVolumeIcon.current.src = mute;
          if (this.volumeProgress.current)
            this.volumeProgress.current.style.backgroundSize = "0% 100%";
          trackVideoEvent(EVENTS.VIDEO.ACTIONS.MUTE, currentVideo);
        });
      }
    }
  }

  renderDesktopHeader() {
    // eslint-disable-next-line no-unused-vars
    const { isBuffering} = this.props;
    const {UIActive} = this.state;
    return (
      <>
        <button
          ref={this.refDesktopBack}
          className="desktop-back"
          onClick={() => {
            // eslint-disable-next-line import/no-named-as-default-member
            history.goBack();
          }}
          disabled={!UIActive}
        >
          <img ref={this.refDesktopBackIcon} src={back} alt="back"/>
          <Localised node="buttons.stop_playing"/>
        </button>
        {isBuffering ? (
          <div className="logo spinning">
            <Lottie options={this.spinnerOptions} width={60}/>
          </div>
        ) : (
          <img src={rlaxxLogo} className="logo" alt="rlaxx-logo"/>
        )}
      </>
    );
  }

  renderMobileControllers() {
    const {volumeLevel, UIActive} = this.state;
    const {deviceType} = this.props;
    return (
      <div className={`buttons-group ${deviceType === "tablet" && "tablet"}`}>
        <div
          className="target-wrapper"
          role="button"
          onClick={() => {
            // eslint-disable-next-line import/no-named-as-default-member
           this.cancelFullScreen()


          }}
        >
          <button ref={this.refMobileBack} disabled={!UIActive}>
            <img ref={this.refMobileBackIcon} src={back} alt="back"/>
            <Localised node="buttons.back"/>
          </button>
        </div>
        <div className="target-wrapper" role="button" onClick={this.toggleVolume}>
          <div
            ref={this.volumeRef}
            className={`volume-div ${device?.device.brand.indexOf("Apple") !== -1 && "apple"} ${
              volumeLevel !== 0 && "unmute"
            }`}
          >
            <button
              ref={this.refVolumeButton}
              className="control-button volume"
              disabled={!UIActive}
            >
              <img
                className="control-icon"
                ref={this.refVolumeIcon}
                src={volumeLevel !== 0 ? unmute : mute}
                alt="volume"
              />
            </button>
            {device?.device.brand.indexOf("Apple") === -1 && (
              <input
                ref={this.volumeProgress}
                onChange={this.setVolume}
                className="volume-input"
                type="range"
                min={0}
                max={1}
                value={volumeLevel}
                step={0.02}
                disabled={!UIActive}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAdCurrentVideoFollow = (type) => {
    const {UIActive} = this.state;
    const {currentVideo, screenSize} = this.props;
    return (
      <CurrentVideoFollow
        type={type}
        currentVideo={currentVideo}
        screenSize={screenSize}
        UIActive={UIActive}
      />
    );
  };

  renderControllers() {
    const {volumeLevel, currentTime, duration, UIActive, isPlay, fullscreen} = this.state;
    // eslint-disable-next-line no-unused-vars
    const {isLoading, screenSize, deviceType} = this.props;
    return (
      <>
        <ProgressBarVod
          refProgress={this.refProgressbar}
          currentTime={currentTime}
          duration={duration}
          setCurrentTime={this.setProgress}
          screenSize={screenSize}
          UIActive
        />

        {deviceType === "desktop" && (
          <div className="buttons-group">
            <div ref={this.volumeRef} className={`volume-div ${volumeLevel !== 0 && "unmute"}`}>
              <button
                ref={this.refVolumeButton}
                className="control-button volume"
                onClick={this.toggleVolume}
                disabled={!UIActive}
              >
                <img
                  className="control-icon"
                  ref={this.refVolumeIcon}
                  src={volumeLevel !== 0 ? unmute : mute}
                  alt="volume"
                />
              </button>
              <input
                ref={this.volumeProgress}
                onChange={this.setVolume}
                className="volume-input"
                type="range"
                min={0}
                max={1}
                value={volumeLevel}
                step={0.02}
                disabled={!UIActive}
              />
            </div>

            <div className="control-button-left">
              <button
                ref={this.refDesktopRewind}
                className="control-button"
                onClick={this.rewind}
                disabled={!UIActive}
              >
                <img
                  ref={this.refDesktopRewindIcon}
                  className="control-icon"
                  src={rewind}
                  alt="rewind"
                />
              </button>
              <button
                ref={this.refDesktopPlayPause}
                className="control-button"
                onClick={() => {
                  this.toggleVideo();
                }}
                disabled={!UIActive}
              >
                <img
                  ref={this.refPlayPauseIcon}
                  className="control-icon"
                  src={isPlay ? pause : play}
                  alt="pause"
                />
              </button>
              <button
                ref={this.refDesktopForward}
                className="control-button"
                onClick={() => {
                  this.forward();
                }}
                disabled={!UIActive}
              >
                <img
                  ref={this.refDesktopForwardIcon}
                  className="control-icon"
                  src={forward}
                  alt="forward"
                />
              </button>
            </div>
            <div className="control-button-right">
              <button
                ref={this.refDesktopFullscreen}
                id="fullScreen"
                className="control-button"
                onClick={() => {
                  this.toggleFullScreen();
                }}
                disabled={!UIActive}
              >
                <img
                  ref={this.refDesktopFullscreenIcon}
                  className="control-icon"
                  src={fullscreen ? windowed : fullscreenIcon}
                  alt="full-screen"
                />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  renderMobileAdLabel = (type) => {
    const {currentVideo, screenSize, adTotal, adRemaining, deviceType} = this.props;
    const {programGuideExtended, UIActive} = this.state;

    return (
      <div className={`ad-part ${deviceType === "tablet" && "tablet"} ${!UIActive && "inactive"}`}>
        <CurrentVideoFollow
          type={type}
          currentVideo={currentVideo}
          screenSize={screenSize}
          UIActive={UIActive}
        />
        {!programGuideExtended && (
          <AdCounterRing
            adRemaining={adRemaining}
            adTotal={adTotal}
            UIActive={UIActive}
            screenSize={screenSize}
            deviceType={deviceType}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      isAd,
      isLoading,
      isBuffering,
      screenSize,
      adRemaining,
      adTotal,
      currentVideo,
      deviceType,
      rotation
    } =
      this.props;
    const {UIActive, isPlay, fadedRewind, fadedForward, fadedPlay, pressed} = this.state;

    return (
      <div className="player-controller-wrapper">
        {deviceType !== "desktop" && rotation === "portrait" && (
          <RotateWarning type="landscape"/>
        )}
        {(isLoading || isBuffering) && !UIActive && (
          <div className={`loading-view ${deviceType !== "desktop" && "mobile"}`}>
            <Lottie options={this.spinnerOptions} width={deviceType !== "desktop" ? 60 : 40}/>
          </div>
        )}
        {isAd && deviceType !== "desktop" && this.renderMobileAdLabel(!UIActive ? 1 : 2)}
        {isAd && deviceType === "desktop" && (
          <AdCounterRing
            adRemaining={adRemaining}
            adTotal={adTotal}
            UIActive={UIActive}
            screenSize={screenSize}
            deviceType={deviceType}
          />
        )}
        <CSSTransition
          in={UIActive}
          appear
          timeout={250}
          onEnter={() => this.onCSSTransitionEnter()}
          onExited={() => this.onCSSTransitionExited()}
          classNames={`${deviceType !== "desktop" ? "home-transition-mobile" : "home-transition"}`}
        >
          <div
            ref={this.playerRef}
            className={`controller-wrapper ${deviceType}`}
          >
            <div ref={this.refControllerHeader} className="controller-header">
              {deviceType === "desktop"
                ? this.renderDesktopHeader()
                : this.renderMobileControllers()}
            </div>

            {deviceType !== "desktop" && !isAd && (
              <div ref={this.refButtonGroup}
                   className={`button-group ${deviceType === "tablet" && "tablet"}`}>
                <CSSTransition in={fadedRewind}
                               appear
                               timeout={75}
                               classNames="faded-rewind">
                  <button
                    ref={this.refMobileRewind}
                    className="control-button"
                    onClick={(e) => {
                      e.preventDefault()
                      this.rewind()
                    }}
                    disabled={!UIActive}
                    onTouchStart={() => {
                      this.buttonPressed(true)
                      this.fadedOutRewind(true)
                    }}
                    onTouchEnd={() => {
                      this.buttonPressed(false)
                      this.fadedOutRewind(false)
                    }
                    }
                    onAnimationEnd={() => {
                      if (pressed) this.fadedOutRewind(false)
                    }}
                  >
                    <img
                      ref={this.refMobileRewindIcon}
                      className="control-icon rewind"
                      src={rewind}
                      alt="rewind"
                      onClick={(e) => e.preventDefault()}
                    />
                  </button>
                </CSSTransition>
                {isBuffering ? (
                  <div className="control-spinner">
                    <Lottie options={this.spinnerOptions}
                            width={deviceType === "tablet" ? 55 : 40}/>
                  </div>
                ) : (
                  <CSSTransition in={fadedPlay}
                                 appear
                                 timeout={75}
                                 classNames="faded-play">
                    <button
                      ref={this.refMobilePlayPause}
                      className="control-button"
                      disabled={!UIActive}
                      onClick={(e) => {
                        e.preventDefault()
                        this.toggleVideo();
                      }}
                      onTouchStart={() => {
                        this.buttonPressed(true)
                        this.fadedOutPlay(true)
                      }}
                      onTouchEnd={() => {
                        this.buttonPressed(false)
                        this.fadedOutPlay(false)
                      }
                      }
                      onAnimationEnd={() => {
                        if (pressed) this.fadedOutPlay(false)
                      }}>

                      <img
                        ref={this.refPlayPauseIcon}
                        className="control-icon play-pause"
                        src={isPlay ? pause : play}
                        alt="play-pause"
                        onClick={(e) => e.preventDefault()}
                      />
                    </button>
                  </CSSTransition>
                )}
                <CSSTransition in={fadedForward}
                               appear
                               timeout={75}
                               classNames="faded-forward">
                  <button
                    ref={this.refMobileForward}
                    className="control-button"
                    onClick={(e) => {
                      e.preventDefault()
                      this.forward();
                    }}
                    disabled={!UIActive}
                    onTouchStart={() => {
                      this.buttonPressed(true)
                      this.fadedOutForward(true)
                    }}
                    onTouchEnd={() => {
                      this.buttonPressed(false)
                      this.fadedOutForward(false)
                    }
                    }
                    onAnimationEnd={() => {
                      if (pressed) this.fadedOutForward(false)
                    }}>
                    <img
                      ref={this.refMobileForwardIcon}
                      className="control-icon forward"
                      src={forward}
                      alt="forward"
                      onClick={(e) => e.preventDefault()}
                    />
                  </button>
                </CSSTransition>
              </div>
            )}

            <div className={`controller-buttons ${isAd && "ad-plays"}`}>
              <div className="content-title">
                <p>{getAssetTitle(currentVideo)}</p>
              </div>
              {isAd && UIActive && deviceType === "desktop" && this.renderAdCurrentVideoFollow(2)}
              {!isAd && this.renderControllers()}
            </div>
          </div>
        </CSSTransition>
        {isAd && !UIActive && deviceType === "desktop" && this.renderAdCurrentVideoFollow(1)}
      </div>
    );
  }
}


PlayerControllers.propTypes = {
  screenSize: PropTypes.number.isRequired,
  isAd: PropTypes.bool,
  location: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentVideo: PropTypes.object,
  isBuffering: PropTypes.bool,
  isLoading: PropTypes.bool,
  adTotal: PropTypes.number,
  adRemaining: PropTypes.number
}

PlayerControllers.defaultProps = {
  isAd: undefined,
  location: undefined,
  currentVideo: {},
  isBuffering: undefined,
  isLoading: undefined,
  adTotal: undefined,
  adRemaining: undefined
}

const mapStateToProps = state => ({
  currentVideo: state.player.currentVideo,
  isPlaying: state.player.isPlaying,
  isBuffering: state.player.isBuffering,
  isLoading: state.player.isLoading,
  isAd: state.player.isAd,
  adRemaining: state.player.adRemaining,
  adTotal: state.player.adTotal,
  screenSize: state.app.screenSize,
  deviceType: state.app.deviceType,
  rotation:state.app.rotation,
  currentLiveChannel:state.app.currentChannel
});

PlayerControllers.contextType = PlayerContext;

export default connect(mapStateToProps, null, null, {forwardRef: true})(PlayerControllers);
