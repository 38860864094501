/* eslint-disable react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from "react";
import './style.scss'
import {CSSTransition} from "react-transition-group";
import DeviceDetector from "device-detector-js";
import Lottie from "react-lottie";
import AdCounterRing from "../../components/ad-counter-label/ad-counter";
import back from "../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg";
import Localised from "../../components/localised";
import unmute from "../../assets/rlaxxTV_Icons_SVG_220114/icon/unmute/white.svg";
import mute from "../../assets/rlaxxTV_Icons_SVG_220114/icon/mute/white.svg";
import rewind from "../../assets/rlaxxTV_Icons_SVG_220114/icon/rewind/white.svg"
import forward from "../../assets/rlaxxTV_Icons_SVG_220114/icon/forward/white.svg"
import pause from "../../assets/rlaxxTV_Icons_SVG_220114/icon/pause/white.svg";
import play from "../../assets/rlaxxTV_Icons_SVG_220114/icon/play/white.svg"
import leftArrow from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-left/white.svg";
import {getAssetTitle, trackAssetEvent} from "../../helpers/assets";
import CurrentVideoFollow from "../../components/ad-counter-label/ad-label";
import ProgressBarHome from "../../components/progressbar-home";
import {getCurrentDate} from "../../helpers/channels";
import {EVENTS, trackVideoEvent} from "../../helpers/tracking";
import {isPortrait, mobileSize} from "../../helpers/measurements";
import PlayerContext from "../../storage/contexts/PlayerContext";
import {pad2} from "../../utils";
import ProgramGuideView from "../program-guide-view";
import RotateWarning from "../rotate-warning";
import {PLAYER_LIVE_STATUS_CHANGE} from "../player";
import spinnerData from "../../helpers/spinner.json";




const deviceDetector=new DeviceDetector();
const { userAgent } = window.navigator;
const device = deviceDetector.parse(userAgent);



class HomeLandscape extends React.Component {
  constructor(props) {
    super(props);
    const { isMuted } = this.props;
    this.state = {
      isLive: true,
      title:'',
      intervalEndTime:0,
      intervalStartTime:0,
      volumeLevel: isMuted ? 0 : 1,
      programGuideExtended: false,
      progressBarScrolling:false,
      fadedPlay:false,
      fadedRewind:false,
      fadedForward:false,
      pressed:false,
      isBackToPortrait:false
    };
    this.volumeRef = React.createRef();
    this.refPlayPauseIcon = React.createRef();
    this.refVolumeIcon = React.createRef();
    this.volumeProgress = React.createRef();
    this.refProgressbar = React.createRef();
    this.setVolume = this.setVolume.bind(this);
    this.toggleVolume = this.toggleVolume.bind(this);
  }

  componentDidMount() {
    this.fullScreen();
    const { addEventListeners, displayHideUI } = this.props;
    if (typeof displayHideUI === "function") displayHideUI();
    if (typeof addEventListeners === "function") addEventListeners();
    document.addEventListener(PLAYER_LIVE_STATUS_CHANGE, this.updateData());
    this.updateData()
    this.updateInterval = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      liveChannelId,
      currentAsset,
      currentVideo,
      isAd
    } = this.props

    if (
      prevProps.id !== id ||
      prevProps.liveChannelId !== liveChannelId ||
      prevProps.currentVideo.startUnix !== currentVideo.startUnix ||
      prevProps.currentVideo.endUnix !== currentVideo.endUnix ||
      prevProps.currentVideo.content.id !== currentVideo.content.id||
      prevProps.currentAsset.id!==currentAsset.id
    ) {
      this.updateData();
    }
    if(prevProps.isAd!==isAd && prevProps.isAd===true && isAd===false ){
      // eslint-disable-next-line react/destructuring-assignment
      this.context.current.setLive(false)
      this.updateIsLive()
    }
  }

  componentWillUnmount() {
    const { stopDisplaying } = this.props;
    stopDisplaying();
    this.cancelFullScreen();
  }



  getCurrentTime = () => {
    const { current } = this.context;
    if (current) return current.getCurrentTime();
    return 0;
  };



  // eslint-disable-next-line react/sort-comp
  toggleVolume() {
    const { currentVideo, changeIsMuted } = this.props;
    const { current } = this.context;
    if (current) {
      if (current.isMuted()) {
        const volume = current.getVolume();
        this.setState({ volumeLevel: volume }, () => {
          if (typeof changeIsMuted === "function") changeIsMuted(false);
          current.unmute();
          trackAssetEvent(EVENTS.VIDEO.ACTIONS.UNMUTE, currentVideo);
          if (this.volumeProgress.current) {
            const { min, max, value } = this.volumeProgress.current;
            this.volumeProgress.current.style.backgroundSize = `${
              ((value - min) * 100) / (max - min)
            }% 100%`;
          }
        });
      } else {
        this.setState({ volumeLevel: 0 }, () => {
          if (typeof changeIsMuted === "function") changeIsMuted(true);
          current.mute();
          trackAssetEvent(EVENTS.VIDEO.ACTIONS.MUTE, currentVideo);
          if (this.volumeProgress.current)
            this.volumeProgress.current.style.backgroundSize = "0% 100%";
        });
      }
    }
  }

  setVolume() {
    if (this.volumeProgress.current) {
      const { displayHideUI } = this.props;

      if (typeof displayHideUI === "function") displayHideUI();
      const { value, min, max } = this.volumeProgress.current;
      this.setState({ volumeLevel: Number(value) }, () => {
        const { current } = this.context;
        const { volumeLevel } = this.state;
        current.setVolume(volumeLevel);
        this.volumeProgress.current.style.backgroundSize = `${
          ((volumeLevel - min) * 100) / (max - min)
        }% 100%`;
        if (volumeLevel === 0) {
          this.refVolumeIcon.current.src = mute;
        } else {
          this.refVolumeIcon.current.src = unmute;
        }
      });
    }
  }

  fadedOutPlay=(cond)=> {
    if(cond===false){
      this.setTo=setTimeout(()=>{
        this.setState({fadedPlay:cond},()=>{
          clearTimeout(this.setTo);
        })
      },150)

    }else{
      this.setState({fadedPlay:cond})
    }
  }

  fadedOutRewind=(cond)=> {
    if(cond===false){
      this.setTo=setTimeout(()=>{
        this.setState({fadedRewind:cond},()=>{
          clearTimeout(this.setTo);
        })
      },150)

    }else{
      this.setState({fadedRewind:cond})
    }
  }

  fadedOutForward=(cond)=> {
    if(cond===false){
      this.setTo=setTimeout(()=>{
        this.setState({fadedForward:cond},()=>{
          clearTimeout(this.setTo);
        })
      },150)

    }else{
      this.setState({fadedForward:cond})
    }
  }

  isProgressBarScrolling=(bool)=>{
    this.setState({progressBarScrolling:bool})
  }

  buttonPressed=(cond)=>{
    this.setState({pressed:cond})
  }

  setBackToPortrait=()=>this.setState({isBackToPortrait:true})


  checkLiveState = () => {
    const { current } = this.context;
    const { id, liveChannelId } = this.props;
    // eslint-disable-next-line eqeqeq
    if (id != liveChannelId) return true;
    if (current) return current.isLive;
    return true;
  };

  // eslint-disable-next-line no-unused-vars
  updateIsLive = (checkFocus = false) => {
    const newLiveState = this.checkLiveState();
    this.setState({ isLive: newLiveState });
  };

  toggleVideo = () => {
    const { current } = this.context;

      if (current) {
        current.toggleVideo();
        this.refPlayPauseIcon.current.src = current.isPaused ? play : pause;
      }

  };

  forward = () => {
    const { isLive } = this.state;
    const { current } = this.context;

      if (!isLive && current) return current.forward("live");
      return null;



  };

  updateData = () => {
    const{currentVideo,currentAsset, id, liveChannelId, timeShifting}=this.props;
    const assetToShow=Number(id)!==Number(liveChannelId)||!timeShifting?currentAsset:currentVideo;

    this.setState({
      intervalEndTime: `${this.generateIntervalTime(assetToShow).end}`,
      intervalStartTime:`${this.generateIntervalTime(assetToShow).start} `,
      isLive: this.checkLiveState(),
      title:getAssetTitle(assetToShow)
    });
  };

  forwardLive = () => {
    const { isLive } = this.state;
    const { current } = this.context;
    if (!isLive && current) {
      current.forward("live", true);
      this.updateIsLive(true);
    }
    return null;
  };

  rewind() {

      const { current } = this.context;
      if (current) current.rewind();
      this.updateIsLive();


  }

  fullScreen() {
    const { currentVideo, addRotateWarningForFullScreen } = this.props;
    const app = document.getElementById("root");
    if (app.requestFullScreen) {
      app.requestFullScreen();
    } else if (app.webkitEnterFullscreen) {
      app.webkitEnterFullscreen(); // for iphone this code worked
    }else if (app.webkitRequestFullScreen) {
      app.webkitRequestFullScreen();
    } else if (app.mozRequestFullScreen) {
      app.mozRequestFullScreen();
    }else if (app.mozFullScreen) {
      app.mozFullScreen();
    } else if (app.msRequestFullscreen) {
      app.msRequestFullscreen();
    }

    addRotateWarningForFullScreen(true);

    trackVideoEvent(EVENTS.VIDEO.ACTIONS.FULLSCREEN, currentVideo);
  }

  toggleFullScreen() {
    const { currentVideo } = this.props;
    if (
      document.fullscreenElement ||
      document.msFullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }else if(document.mozCancelFullScreen){
        document.mozCancelFullScreen();
      } else {
        document.requestFullScreen();
      }
      trackVideoEvent(EVENTS.VIDEO.ACTIONS.CANCEL_FULLSCREEN, currentVideo);
    }

    if (
      !document.fullscreenElement &&
      !document.msFullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    )
      this.fullScreen();
  }

  cancelFullScreen() {
    const { currentVideo, addRotateWarningForFullScreen } = this.props;

    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      } else {
        document.requestFullScreen();
      }
    }
    addRotateWarningForFullScreen(false);
    trackVideoEvent(EVENTS.VIDEO.ACTIONS.CANCEL_FULLSCREEN, currentVideo);
  }

  generateIntervalTime = (dataToShow) => {

    const startTime = new Date(dataToShow.startUnix * 1000);
    const endTime = new Date(dataToShow.endUnix * 1000);
    const startHhMm = `${pad2(startTime.getHours())}:${pad2(startTime.getMinutes())}`;
    const endHhMm = `${pad2(endTime.getHours())}:${pad2(endTime.getMinutes())}`;
    return {start:startHhMm,end:endHhMm}
  }


  setCurrentTime = (newVideoPosition) => {
    const { current } = this.context;
    const { displayHideUI } = this.props;
    if (typeof displayHideUI === "function") displayHideUI();
    if (current) current.setCurrentTime(newVideoPosition);
  };

  play = () => {
    const { current } = this.context;
    if (current) {
      current.play();
      this.updateIsLive();
    }
  };

  pause = () => {
    const { current } = this.context;
    if (current) {
      current.pause();
      this.updateIsLive();
    }
  };

  onCSSTransitionEnter = () => {
    const { refLandscapeWrapper } = this.props;
    if (refLandscapeWrapper.current) refLandscapeWrapper.current.style.display = "block";
  };

  onCSSTransitionExited = () => {
    const { refLandscapeWrapper } = this.props;
    if (refLandscapeWrapper.current) refLandscapeWrapper.current.style.display = "none";
  };

  extendProgramGuide() {
    const { programGuideExtended } = this.state;
    const { stopHiding, stopDisplaying } = this.props;
    this.setState({ programGuideExtended: !programGuideExtended }, () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.programGuideExtended) {
        stopHiding();
      } else {
        stopDisplaying();
      }
    });
  }

  renderControllers = () => {
    const { volumeLevel } = this.state;
    return (
      <div className="button-group">
        <div className="target-wrapper">
        <button
          onClick={() => {
            this.setBackToPortrait();
            this.cancelFullScreen();
          }}
        >
          <img src={back} alt="back" />
          <Localised node="buttons.back" />
        </button>
        </div>
        <div className="target-wrapper volume">
        <div ref={this.volumeRef} className={`volume-div
        ${volumeLevel !== 0 && "unmute"}  ${device?.device.brand.indexOf("Apple")!==-1 && "apple" } `}>
          <button className="control-button volume" onClick={() => this.toggleVolume()}>
            <img
              className="control-icon"
              ref={this.refVolumeIcon}
              src={volumeLevel !== 0 ? unmute : mute}
              alt="volume"
            />
          </button>
          { device?.device.brand.indexOf("Apple")===-1 && <input
            ref={this.volumeProgress}
            onChange={this.setVolume}
            className="volume-input"
            type="range"
            min={0}
            max={1}
            value={volumeLevel}
            step={0.02}
          />}
        </div>
        </div>
      </div>
    );
  };

  renderAdCurrentVideoFollow = (type) => {
    const { currentVideo, screenSize, UIActive, adTotal, adRemaining, deviceType } = this.props;
    const { programGuideExtended } = this.state;

    return (
      <div className={`ad-part ${!UIActive && "inactive"}`}>
        <CurrentVideoFollow
          type={type}
          currentVideo={currentVideo}
          screenSize={screenSize}
          UIActive={UIActive}
        />
        {!programGuideExtended && (
          <AdCounterRing
            adRemaining={adRemaining}
            adTotal={adTotal}
            UIActive={UIActive}
            screenSize={screenSize}
            deviceType={deviceType}
          />
        )}
      </div>
    );
  };

  render() {
    const { isLive, programGuideExtended,intervalEndTime,intervalStartTime,title,progressBarScrolling,
      fadedPlay, fadedRewind, fadedForward, pressed, isBackToPortrait } = this.state;
    const {
      channelTitle,
      currentAsset,
      isAd,
      currentFocusChannel,
      isBuffering,
      isLoading,
      currentVideo,
      UIActive,
      liveChannelId,
      changeChannel,
      refLandscapeHeader,
      refLandscapeWrapper,
      refParagraph,
      timeShifting,
      carryAssetFromLandscapeToPortrait,
      deviceType
    } = this.props;
    const time = getCurrentDate();
    const spinnerOptions = {
      loop: true,
      autoplay: true,
      animationData:spinnerData,
    };
    return (
      <div ref={this.refHomeLandscape} className={`home-landscape-wrapper ${deviceType}`}>
        {isPortrait() === "portrait" && <RotateWarning type="portrait" />}
        {isBackToPortrait && <RotateWarning type="portrait" />}
        { isAd && this.renderAdCurrentVideoFollow(!UIActive ? 1 : 2)}
        <CSSTransition
          in={UIActive}
          appear
          timeout={250}
          onEnter={() => this.onCSSTransitionEnter()}
          onExited={() => this.onCSSTransitionExited()}
          classNames="home-transition"
        >
          <div ref={refLandscapeWrapper} className="controller-wrapper">
            <div ref={refLandscapeHeader} className="controller-header">
              {this.renderControllers()}
            </div>
            {!isAd && !programGuideExtended && timeShifting && (
              <div className="button-group">
                <CSSTransition  in={fadedRewind}
                                appear
                                timeout={75}
                                classNames="faded-rewind">
                <button className="control-button"
                        onClick={(e) =>{
                          e.preventDefault()
                          this.rewind()
                        }}
                        onTouchStart={()=>{
                          this.buttonPressed(true)
                          this.fadedOutRewind(true)}}
                        onTouchEnd={()=>{this.buttonPressed(false)
                          this.fadedOutRewind(false)
                          }
                        }
                        onAnimationEnd={ ()=>{if(pressed) this.fadedOutRewind(false)}}
                >

                  <img className="control-icon rewind" src={rewind} alt="rewind" />

                </button>
              </CSSTransition>
                {isBuffering || isLoading ? (
                  <div className="buffering">
                    <Lottie
                      options={spinnerOptions}
                      width={deviceType==="tablet"?80:60}
                    />
                  </div>
                ) : (
                  <CSSTransition  in={fadedPlay}
                                  appear
                                  timeout={75}
                                  classNames="faded-play">
                  <button
                    className={`control-button ${(isBuffering || isLoading) && "hide"}`}
                    onClick={(e) => {
                      e.preventDefault()
                      this.toggleVideo();
                    }}
                    onTouchStart={()=>{
                      this.buttonPressed(true)
                      this.fadedOutPlay(true)}}
                    onTouchEnd={()=>{this.buttonPressed(false)
                      this.fadedOutPlay(false)
                    }
                    }
                    onAnimationEnd={ ()=>{if(pressed) this.fadedOutPlay(false)}}>

                    <img
                      ref={this.refPlayPauseIcon}
                      className="control-icon play-pause"
                      src={pause}
                      alt="play-pause"
                    />

                  </button>
                  </CSSTransition>
                  )}
                <CSSTransition  in={fadedForward}
                                appear
                                timeout={75}
                                classNames="faded-forward">
                <button
                  className="control-button"
                  onClick={(e) => {
                    e.preventDefault()
                    this.forward();
                  }}
                  disabled={!!isLive}
                  onTouchStart={()=>{
                    this.buttonPressed(true)
                    this.fadedOutForward(true)}}
                  onTouchEnd={()=>{this.buttonPressed(false)
                    this.fadedOutForward(false)
                  }
                  }
                  onAnimationEnd={ ()=>{if(pressed) this.fadedOutForward(false)}}>

                  <img className="control-icon forward" src={forward} alt="forward" />
                </button>
                </CSSTransition>
              </div>
            )}

            {!programGuideExtended && (
              <div className={`controller-buttons ${isAd && "ad-plays"}`}>
                <div className={`info-wrapper ${progressBarScrolling && "scrolling"}`}>
                <div className="channel-title">
                  <p ref={refParagraph}>{channelTitle}</p>
                </div>
                <div className="content-title">
                  <p ref={refParagraph}>
                    {" "}
                    { isAd&&!timeShifting?getAssetTitle(currentVideo):title}
                  </p>
                  {!isAd && deviceType==="tablet" && <span className="start">{intervalStartTime} - {intervalEndTime}</span> }
                </div>

                </div>
                {!isAd && (
                  <div className="timeline">
                    {deviceType==="mobile" && <span className="start">{intervalStartTime}</span>}
                    <ProgressBarHome
                      deviceType={deviceType}
                      isLive={isLive}
                      isProgressBarScrolling={this.isProgressBarScrolling}
                      startTime={currentVideo.startUnix}
                      startRealTime={currentAsset.startUnix}
                      videoTime={this.getCurrentTime()}
                      currentTime={time.getTime() / 1000}
                      endRealTime={currentAsset.endUnix}
                      endTime={currentVideo.endUnix}
                      currentLiveChannel={liveChannelId}
                      focusedChannelId={currentFocusChannel.id}
                      play={this.play}
                      screenSize={mobileSize-1}
                      pause={this.pause}
                      setCurrentTime={this.setCurrentTime}
                      forwardLive={this.forwardLive}
                      timeShifting={timeShifting}
                    />
                    {deviceType==="mobile" && <span className="end">{intervalEndTime}</span>}
                  </div>
                )}
              </div>
            )}
          </div>
        </CSSTransition>

        <CSSTransition in={UIActive} appear timeout={250} classNames="landscape-epg-transition">
          <div className={`landscape-program-guide  ${programGuideExtended && "extended"}`}>

            <button
              className={`control-button browse-channel ${programGuideExtended && "open"}`}
              onClick={() => {
                this.extendProgramGuide();
              }}
            >
              <div className="target-wrapper browse-channel">
              <Localised node={`buttons.${programGuideExtended ? "close" : "browse_channels"}`} />
              </div>
            </button>

            <CSSTransition in={programGuideExtended} appear timeout={500} classNames="arrow-transition">
              <div
                role="button"
                className="program-guide-arrow"
                onClick={() => {
                  this.extendProgramGuide();
                }}
              >
                <img
                  className="arrow"
                  src={ leftArrow}
                  alt="arrow"
                />
              </div>
            </CSSTransition>
            <ProgramGuideView
              changeChannel={changeChannel}
              currentFocusedChannel={currentFocusChannel}
              carryAssetFromLandscapeToPortrait={carryAssetFromLandscapeToPortrait}
            />
          </div>
        </CSSTransition>
      </div>
    );
  }
}




HomeLandscape.contextType = PlayerContext;

export default HomeLandscape;
