/* eslint-disable react/button-has-type,
import/no-named-as-default-member,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,eqeqeq */
import React from 'react'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import axios from "axios";
import './style.scss'
import Lottie from "react-lottie";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import SwiperSlider from "../../components/OnDemandSlider";
import video, {getEPGIdFromChannel, liveVideoTotalProgress} from "../../helpers/video";
import store, {appActions, playerActions} from "../../storage/store";
import {
  getAssetChannelTitle,
  getAssetDescription,
  getAssetFromID,
  getAssetTitle, trackAssetEvent
} from "../../helpers/assets";
import {
  getChannelFromAsset,
  getCurrentDate, getParsedEpgId,
  // getPartnerIdentifierFromChannel,
  partnerIdentifiers
} from "../../helpers/channels";
import {initLiveAds} from "../../helpers/ads/ads";
import PlayerContext from "../../storage/contexts/PlayerContext";
import CONSTANTS from '../../storage/redux/app-data/constants';
import {images} from "../../helpers";
import history from "../../storage/history";
import back from "../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg";
import logo from "../../assets/images/rlaxx_logo_mobile.svg";
import bottom from '../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-down/white.svg'
import up from '../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-up/white.svg'
import Localised, {getTranslation} from "../../components/localised";
import assets from '../../services/apis/sportradar_api';
import {pad2} from "../../utils";
import {differenceBetweenDays} from "../../components/channelEpg/helpers";
import {appendSRParams, getFromSRAPI, srProperties} from "../../storage/sr-properties";
import {trackPageView, EVENTS, PAGES} from '../../helpers/tracking'
import RotateWarning from "../rotate-warning";
// eslint-disable-next-line no-unused-vars
import { mobileSize} from "../../helpers/measurements";
import {getChannelFromID, getChannelFromSRCat2} from "../../storage/redux/app-data/actions";
import spinnerData from "../../helpers/spinner.json";
import PlayListDetailItem from "../../components/playlist-detail-item";
import {stripHtml} from "../../helpers/html-editor";
import {getSRAssetSerieData} from "../../helpers/partners/sportradar";

class ContentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: undefined,
      assetTitle: undefined,
      backgroundImage: undefined,
      channel:undefined,
      loadedChannel: store.getState().app.currentChannel,
      descriptionCollapsed: false,
      collection: undefined,
      // eslint-disable-next-line react/no-unused-state
      collectionData:[],
    }

    this.refDescription = React.createRef();
    this.refMoreButton = React.createRef();
    this.refContentText = React.createRef();
    this.changeContentFromRelated = this.changeContentFromRelated.bind(this)
    this.getCollectionElement = this.getCollectionElement.bind(this);
    this.playAssetFromCollection = this.playAssetFromCollection.bind(this);


  }

  componentDidMount() {
    const {deviceType}=this.props;
    this.loadAsset(0);
    window.addEventListener('resize', ()=>{
      this.forceUpdate();
      }
    )
    if(deviceType!=="desktop"){
      if(document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement){

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullScreen) { /* Safari */
          document.webkitExitFullScreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }else if(document.mozCancelFullScreen){
          document.mozCancelFullScreen();
        }
        else{
          document.requestFullScreen();
        }
      }
    }


  }

  componentDidUpdate(prevProps) {
    const {assetId} = this.props;
    if (prevProps.assetId !== assetId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({asset: undefined}, () => {
        this.loadAsset(1);

      })
    }
  }

  componentWillUnmount() {
    this.setState({asset: undefined})
  }

  set assetDetails(asset) {
    const {pageView, teaserType} = this.props
    const {collection} = this.state
    const title = getAssetTitle(asset) || undefined;
    const serieData = getSRAssetSerieData(asset);
    const seasons = getTranslation("general.season_short")
    const episodes = getTranslation("general.episode_short")
    this.setState({backgroundImage: this.backgroundImage(asset)});
    getChannelFromAsset(asset)
      .then(channel => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({channel});
        // eslint-disable-next-line react/destructuring-assignment
      })
    if (serieData && serieData.episode) {
      this.setState({assetTitle: `${seasons}${serieData.season}
      ${episodes}${serieData.episode}: ${title}`});
    }
    else {
      this.setState({assetTitle: title});
    }
    if (teaserType === 'collection') {
      trackPageView(pageView, 'playlist', collection.title.en)
    } else {
      trackPageView(pageView, 'detail', getAssetChannelTitle(asset),
        getAssetTitle(asset))
    }

    // eslint-disable-next-line import/no-named-as-default-member
    video.getStreamableAsset(asset)
      .then(streamableAsset => {
        this.setState({asset: streamableAsset});
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('There was an error while retrieving a Streamable Asset:', error);
        // eslint-disable-next-line react/no-unused-state
        this.setState({asset, nonPlayable: true});
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getAsset(assetType, partnerId, assetId) {
    if (assetType === 'epg') {
      return store.dispatch(appActions.getAssetFromEPGChannel(partnerId, assetId));
    }
    if (assetType === 'vod') {
      return getAssetFromID(assetId, partnerIdentifiers.SPORTRADAR)
        .then(resp => resp?.data?.data)
        // eslint-disable-next-line prefer-object-spread
        .then(vodAsset => Object.assign({}, vodAsset, {content: vodAsset.content[0]}));
    }
    return Promise.reject(`${assetType} is not a valid asset type.`);
  }


  getCollectionElement(assetIndex) {
    const {teaserType} = this.props
    const {collection} = this.state;
    if (teaserType === 'collection') {
      this.assetDetails = collection.content[assetIndex]

    }

  }



  getTiming() {
    const {assetType} = this.props;
    if (assetType === 'epg') {
      return this.renderProgress()
    }
    return null;
  }

  fontSizeChanger = (text,expectedSize,originalFontSize=50.52 ) => {
    if (!text) return {};
    let fontSize = `${originalFontSize}px`;
    if (parseInt(text.length, 10) >= 10) {
      fontSize = `${expectedSize}px`;
    }
    return  fontSize ;
  };

  generateButtons = () => {
    const {assetType, partnerId, assetId} = this.props;
    const {asset} = this.state;
    if (assetType && partnerId && assetId && asset) {

      if (assetType === 'epg') {
        return video.isCurrentlyPlaying(asset)
          ?
          <div className="content-detail-button-group">
            <div className="target-wrapper" role="button"   onClick={() => {
              this.watchLive()
            }} >
              <button className="watch-button"
              >
                <Localised node="buttons.watch_live" />
              </button>
            </div>
            {!partnerId.startsWith('vevo') &&
            <div className="target-wrapper" role="button"  onClick={() => {
              this.watchFromStart()
            }}>
              <button className="watch-button">
                <Localised node="buttons.watch_start" />
              </button>
            </div>
            }
          </div>
          :
          <div className="content-detail-button-group">
            { !partnerId.startsWith('vevo') &&
            <div className="target-wrapper" role="button" onClick={() => {
              this.watchFromStart()
            }}>
              <button className="watch-button" >
                <Localised node="buttons.play_now" />
              </button>
            </div>}
          </div>
      }
      if (assetType === 'vod') {
        return <div className="content-detail-button-group">
          <div className="target-wrapper" role="button" onClick={() => {
            this.watchFromStart()
          }}>
            <button >
              <Localised node="buttons.play_now" />
            </button>
          </div>
        </div>
      }
    }

    return Error;
  }


  watchLive = () => {
    const {partnerId, closeItem} = this.props;
    const {epg} = store.getState().app;
    history.replace(`/home/${epg[partnerId].channelInfo.id}`)
      closeItem();
  };

  watchFromStart = () => {
    const {asset, nonPlayable} = this.state;
    const {current}=this.context
    const {pageView, assetType, partnerId, epg}=this.props;

    const startTime=0;
    current.setStartTime(startTime)
    if(nonPlayable){
      history.push({ pathname: '/error/501', state: { clearStorage: true } });
    }else{

   //   current.setStartTime(0);
      if(asset){
        if (assetType === 'epg' && video.isCurrentlyPlaying(asset)) {
          const epgId = getParsedEpgId(partnerId);

          const {id} = epg[epgId].channelInfo;
          store.dispatch({
            type: CONSTANTS.SET_CURRENT_CHANNEL,
            payload: { epgId, id }
          });
        } else if (asset.channelId) {
          store
            .dispatch(getChannelFromID(asset.channelId))
            .then(channel => {
              const epgId = getEPGIdFromChannel(channel);
              store.dispatch({
                type: CONSTANTS.SET_CURRENT_CHANNEL,
                payload: { epgId, id: channel.id }
              });
            })
            .catch(e => {
              console.error(e);
            });
        } else {
          store
            .dispatch(
              getChannelFromSRCat2(asset.category2?.id || asset.event?.category2?.id || asset.content?.event?.category2?.id)
            )
            .then(channel => {
              const epgId = getEPGIdFromChannel(channel);
              store.dispatch({
                type: CONSTANTS.SET_CURRENT_CHANNEL,
                payload: { epgId, id: channel.id }
              })
            })
            .catch(e => {
              console.error(e);
              throw e
            });
        }

    }

      store.dispatch(playerActions.setPlayingVideo(asset)).then(() => {

        if (assetType === 'epg' && video.isCurrentlyPlaying(asset)){
          trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_RESTART, asset,'on-demand');
        }else{
          trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL,asset,'on-demand');
        }

        trackPageView(`${pageView}`, `${PAGES.PLAYER}`, getAssetChannelTitle(asset),
          getAssetTitle(asset))

        history.push('/player');
      })

    }




  };

  calculateProgress = (toDate, duration) =>
    Math.ceil(((duration - this.calculateSecRemaining(toDate)) * 100) / duration);

  calculateSecRemaining=(toDate)=>{
    const currentTime = getCurrentDate();
    const endTime = toDate;
    if (currentTime > endTime) return 0;

    const remaining = Math.ceil((endTime - currentTime) / 1000);

    return remaining >= 0 ? remaining : 0;
  };

  requestNextPage = () => {
    const { collectionData, collection } = this.state;
    const {
      meta,
      links: { next }
    } = collectionData;
    const currentPage = meta?.current_page;

    // todo: concat the content
    // todo: override meta and links
    if (next!==null && collection && !this.requesting) {
      this.requesting = true;

      axios
        .get(appendSRParams(next))
        .then(({ data }) => {
          if (data.meta.current_page > currentPage) {
            collectionData.meta = data.meta;
            collectionData.links = data.links;
            collection.content = [...collection.content, ...data.data.content];
          }
        })
        .catch(error => {
          console.error("Couldn't load the next page:", error);
        })
        .then(() => {
          this.requesting = false;
          this.forceUpdate();
        });
    }
  };


  changeContentFromRelated(asset) {
    const {selectItem} = this.props;
    selectItem(asset)
  }

  playAssetFromCollection(assetIndex) {
    const {collection} = this.state;
    const playingAsset = collection.content[assetIndex]
    this.setState({asset: undefined}, () => {
      video.getStreamableAsset(playingAsset)
        .then(streamableAsset => {
          this.setState({asset: streamableAsset});
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('There was an error while retrieving a Streamable Asset:', error);
          // eslint-disable-next-line react/no-unused-state
          this.setState({playingAsset, nonPlayable: true});
        }).then(() => {
        this.watchFromStart()
      })
    })


  }

  loadAsset(update = 0) {
    const {asset} = this.state;
    const {assetType, partnerId, assetId, teaserType, pageId, closeItem} = this.props;
    const {current}=this.context;
    if (asset && teaserType === 'collection') {
      this.assetDetails = asset;
    } else if (!asset) {
      if (teaserType === 'collection') {
        const navItem = srProperties.navigationItems[pageId];
        if (!navItem || !navItem.target || !navItem.target.url) {
          history.replace('/error/404');
        } else {
          getFromSRAPI(navItem.target.url)
            .then(resp => resp?.data?.data?.modules)
            .then(modules => modules?.[0]) // todo  We need the module url from SR
            .then(playlist =>
              assets
                .getModuleData(playlist.id)
                .then(resp => {
                  const collectionData = {meta: resp.data?.meta, links: resp.data?.links};
                  // eslint-disable-next-line react/no-unused-state
                  this.setState({collection: resp.data?.data, collectionData}, () => {
                    const {collection}=this.state;
                    const {content}=collection
                    // eslint-disable-next-line prefer-destructuring
                    this.assetDetails = content[0]
                  });
                })
                .catch(e => {
                  // eslint-disable-next-line no-console
                  console.error(e);
                  // eslint-disable-next-line react/no-unused-state
                  this.setState({collection: playlist, collectionData: []});
                })
            );
        }
      }
      if (assetType && partnerId && assetId) {
        this.getAsset(assetType, partnerId, assetId)
          .then(foundAsset => {
            this.assetDetails = foundAsset;
          })
          .catch(error => {
            // todo: go back
            // eslint-disable-next-line no-console
            if (typeof closeItem === 'function') closeItem();
            console.error('Error getting asset from path >>', error);
          })
          .then(() => {
            const {loadedChannel} = this.state;
            store
              .dispatch(appActions.updateCurrentPlayingVideoFromChannel(loadedChannel.epgId, true))
              .then(playingAsset => {
                if (update === 0) initLiveAds();
                // eslint-disable-next-line eqeqeq
                if (asset?.id != playingAsset?.id) {
                  current.setStartTime(liveVideoTotalProgress(playingAsset.startUnix));
                } else {
                  current.setCurrentTime(liveVideoTotalProgress(playingAsset.startUnix));
                }
              }).catch( ()=> {
                if(!loadedChannel){
                  history.push('/error/404');
                }
            });
          });
      }

    } else {

      const {loadedChannel} = this.state;
      store.dispatch(appActions.updateCurrentPlayingVideoFromChannel(loadedChannel.epgId, true)).then(playingAsset => {
        if (update === 0) initLiveAds();
        if (asset?.id !== playingAsset?.id) {
          current.setStartTime(liveVideoTotalProgress(playingAsset.startUnix));
        } else {
          current.setCurrentTime(liveVideoTotalProgress(playingAsset.startUnix));
        }
      });
    }

  }


  showMore() {
    const { teaserType, deviceType} = this.props;
    const {descriptionCollapsed} = this.state;

    if (this.refMoreButton.current && this.refContentText.current) {
      this.setState({descriptionCollapsed: !descriptionCollapsed}, () => {
        if(descriptionCollapsed) {
          if (teaserType === 'collection') {
            this.refContentText.current.style.webkitLineClamp = '3'
          } else {
            // eslint-disable-next-line no-lonely-if
            if (deviceType!=="mobile") {
              this.refContentText.current.style.webkitLineClamp = '7'
            } else {
              this.refContentText.current.style.webkitLineClamp = '9'
            }
          }

          this.refDescription.current.style.paddingBottom = '0'
        } else {
          this.refContentText.current.style.webkitLineClamp = 'unset'
          this.refDescription.current.style.paddingBottom = '5px'
        }
      })
    }
  }


  addMaskedClass() {
    const {asset} = this.state;
    const { teaserType, deviceType} = this.props;
    const descriptionLength=getAssetDescription(asset).length;
    const lineWidth=Math.floor((window.innerHeight*0.77)/16);
    const lineNum=descriptionLength/lineWidth
    if (teaserType === 'collection' && lineNum >= 3) {
      return 'masked';

    }
    if (deviceType!=="mobile" && lineNum >= 7 || deviceType==="mobile" && lineNum>= 9) {
      return 'masked';
    }

    return '';

  }

  // eslint-disable-next-line class-methods-use-this
  backgroundImage(asset){
    if(asset?.content?.contentPartnerName==='Vevo'){
      return `url(${asset.thumbnail})`
    }
    if(asset) {
      if (asset.content) {
        return `url(${images.getSRContentImage(asset.content?.editorial?.image?.path, '800x450')}`
      }
      return `url(${images.getSRContentImage(asset?.editorial?.image?.path, '800x450')}`
    }
    return null;
  }



  renderProgress() {
    const {asset} = this.state
    const {deviceType}=this.props;
    const time = getCurrentDate();

    let translationNode;
    let replacer;
    if (!asset) return null;

    const startTime = new Date(asset.startUnix * 1000);
    const endTime = new Date(asset.endUnix * 1000);

    const startHhMm = `${pad2(startTime.getHours())}:${pad2(startTime.getMinutes())}`;
    const endHhMm = `${pad2(endTime.getHours())}:${pad2(endTime.getMinutes())}`;


    const differenceDays = differenceBetweenDays(asset.startUnix * 1000);
    if (differenceDays === 0) {
      translationNode = 'general.timing.today';
    } else if (differenceDays === 1) {
      translationNode = 'general.timing.tomorrow';
    } else if (differenceDays > 1) {
      translationNode = 'general.timing.in_days';
      replacer = {days: differenceDays};
    } else if (differenceDays === -1) {
      translationNode = 'general.timing.yesterday';
    } else if (differenceDays < -1) {
      translationNode = 'general.timing.days_ago';
      replacer = {days: Math.abs(differenceDays)};
    }

    return (
      <div className="content-info-progress">
        <div className="content-info-interval">
          {translationNode && !video.isCurrentlyPlaying(asset) && (
            <>
              <Localised node={translationNode} variables={replacer}/>,{' '}
            </>
          )}
          {`${startHhMm} – ${endHhMm}`}
        </div>
        {video.isCurrentlyPlaying(asset) && (
          <div className={`progress ${deviceType==="tablet" && "tablet"}`}>
            <div className="progressed" style={{
              width: `${(time.getTime() / 1000 - asset.startUnix) * 100 / (asset.endUnix - asset.startUnix)}%`,
              backgroundColor: "#FFFFFF"
            }}/>
          </div>
        )}
      </div>
    );
  }



  renderRelated() {
    const {asset} = this.state;
    const {screenSize, selectItem, deviceType} = this.props;
    if (asset && asset.related) {
      return <SwiperSlider   key={asset.id}
                             title="More By rlaxx"
                             type={2}
                             data={asset}
                             selectItem={selectItem}
                             screenSize={screenSize}
                             deviceType={deviceType}
      />

    }
    return null;
  }

  renderCollectionElements() {
    const {collection, asset} = this.state;
    // const {screenSize, deviceType} = this.props;
    if (collection && collection.content && asset) {
      return <div className="playlist-details-wrapper">
        {collection.content.map((item)=><PlayListDetailItem item={item}/>)}
        </div>


      // return <SwiperSlider key={collection.id}
      //                      title={collection.title}
      //                      type={3}
      //                      data={collection}
      //                      currentAsset={asset}
      //                      selectItem={this.getCollectionElement}
      //                      screenSize={screenSize}
      //                      deviceType={deviceType}
      //                      playAsset={this.playAssetFromCollection}
      //                      moreContent={this.requestNextPage}
      // />
    }
    return null;

  }


  renderMoreDescButton() {
    const {screenSize, teaserType} = this.props;
    const {descriptionCollapsed, asset} = this.state;
    const descriptionLength=getAssetDescription(asset).length;
    const lineWidth=Math.floor((window.innerHeight*0.77)/16);
    const lineNum=descriptionLength/lineWidth
    if ((screenSize >= mobileSize && lineNum >= 7)
      || (screenSize < mobileSize && lineNum >= 9)
      || teaserType === 'collection' && lineNum >= 3) {
      return (
        <div className="target-wrapper more" role="button" onClick={() => {
          this.showMore()
        }}>
          <button ref={this.refMoreButton} id="more-description" >
            <img src={descriptionCollapsed ? up : bottom} alt="vertical-arrow"/>
            <Localised node={`buttons.${descriptionCollapsed ?'close':'more'}`}/>
          </button>
        </div>
      );
    }
    return null;
  }



  render() {
    const {screenSize, closeItem, teaserType, partnerId, deviceType, rotation} = this.props;
    const {asset, descriptionCollapsed, collection, channel, assetTitle, backgroundImage} = this.state;
    const spinnerOptions = {
      loop: true,
      autoplay: true,
      animationData:spinnerData,
    };

    return (
      <div ref={this.refContentDetail} className={`content-detail-wrapper ${deviceType==="desktop" && 'desktop'} single-item`}>
        {deviceType==="mobile" && rotation==='landscape'&& <RotateWarning closeItem={closeItem} type='portrait'/>}
        {!asset&&
        <div className="content-detail-wrapper loading">
          <Lottie
            options={spinnerOptions}
            width={60}
          />
        </div>}

        <div id="detail-header" className="content-header">
          <div className="target-wrapper" role="button" onClick={() => {closeItem()}}>
            <button >
              <img src={back} alt="back-button"
                   className={`back ${screenSize<mobileSize && 'mobile'}`}/>
              <Localised node={`buttons.${screenSize >= mobileSize ? 'close' : 'back'}`} />
            </button>
          </div>
          {screenSize < mobileSize && <img className="logo" src={logo} alt="rlaxx-logo"/> }
        </div>
        <SwitchTransition>
          <CSSTransition key={asset?.id}
                         timeout={250}
                         classNames="detail-info-transition">
            <div className={`content-background-item ${deviceType==="tablet" && "tablet"} ${rotation}`}
                 style={{backgroundImage}}>
              <div className="content-info-wrapper">
                <div className="blur"/>
                <div className={`content-detail-title ${teaserType === 'collection' ? 'collection' : ''}`}>
                  {teaserType === 'collection' && collection &&
                  <div className="content-detail-collection-title">{collection.title.en}</div>}
                  {assetTitle}
                </div>
                {teaserType !== 'collection' && this.getTiming()}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>

        {teaserType !== 'collection' && this.generateButtons()}
        <div className={`content-more-info ${deviceType==="tablet" && "tablet"}  ${teaserType === 'collection'&& 'collection'}`}>
          <SwitchTransition>
            <CSSTransition key={asset?.id}
                           timeout={250}
                           classNames="detail-info-transition">
              <div ref={this.refDescription} className={`content-description
                  ${!descriptionCollapsed && this.addMaskedClass()}`} >
                <p className={`${teaserType === 'collection' && 'collection'} ${deviceType==="mobile" && "mobile"}`}
                   ref={this.refContentText}> {stripHtml(getAssetDescription(asset))} </p>
              </div>
            </CSSTransition>
          </SwitchTransition>
          {this.renderMoreDescButton()}
        </div>
        {teaserType === 'collection' &&
        <div className={`content-related ${teaserType === 'collection' &&'collection'} ${deviceType==="tablet" && "tablet"}`}>
          {this.renderCollectionElements()}
        </div>

        }

        {(partnerId===-1 || partnerId.indexOf('vevo')===-1) && teaserType !== 'collection' &&
          (<div className={`content-related  ${deviceType==="tablet" && "tablet"} `}>
          <p className="related-slider-title"><Localised node="on_demand.more_by"
          variables={{ channel:  channel ? channel.title : getAssetChannelTitle(asset) }} /></p>
          {this.renderRelated()}
        </div>)}
      </div>
    )
  }
}

ContentDetail.contextType = PlayerContext;

ContentDetail.propTypes={
  teaserType: PropTypes.string,
  pageId:PropTypes.string,
  assetId:PropTypes.number,
  assetType:PropTypes.string,
  partnerId:PropTypes.number,
  selectItem:PropTypes.func,
  closeItem:PropTypes.func,
  screenSize:PropTypes.number.isRequired,
  pageView:PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  epg:PropTypes.object,

}


ContentDetail.defaultProps = ({
  teaserType: undefined,
  pageId: undefined,
  assetId:undefined,
  assetType:undefined,
  partnerId:undefined,
  selectItem:undefined,
  closeItem:undefined,
  pageView:undefined,
  epg:undefined

})

const mapStateToProps = state => ({
  screenSize: state.app.screenSize,
  epg:state.app.epg,
  deviceType:state.app.deviceType,
  rotation:state.app.rotation
})


export default connect(mapStateToProps,)(ContentDetail)
