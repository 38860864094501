/* eslint-disable react/forbid-prop-types,no-else-return */
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";
import Lottie from "react-lottie";
import history from "../../storage/history";
import PlayerContext from '../../storage/contexts/PlayerContext';
import './style.scss';
import Header from  "../../components/header";
import { video} from "../../helpers";
import HomeInfo from "../../components/home-info";
import store, {appActions} from "../../storage/store";
import {areAdsInitialized, initLiveAds, stopLiveAds} from "../../helpers/ads/ads";
import { getPartnerIdentifierFromChannel} from "../../helpers/channels";
import TabBar from '../../components/tab-bar';
import Spinner from "../../components/spinner";
import ProgramGuideView from "../program-guide-view";
import AdCounterRing from "../../components/ad-counter-label/ad-counter";
import CurrentVideoFollow from "../../components/ad-counter-label/ad-label";
import RotateWarning from "../rotate-warning";
import {EVENTS, PAGES, trackPageView, trackVideoEvent} from '../../helpers/tracking'
import {
  DISPLAY_HIDE_UI_DURATION,
  isPortrait,
  mobileSize
} from "../../helpers/measurements";
import ChannelBarSlider from '../../components/channel-bar-slider';
import HomeLandscape from "../home-landscape";
import spinnerData from "../../helpers/spinner.json";




class Home extends React.Component {
  static identifier = 'home';

  constructor(props) {
    super(props);
    const oldState = localStorage.getItem(Home.identifier);
    if (oldState) {
      this.state = JSON.parse(oldState);
      this.preloaded = true;
    } else {
      this.state = {
        currentFocusChannel:this.getChannelInfo().channelInfo,
        currentAsset: undefined,
        channelPressed: false,
        showHint: false,
        UIActive:true,
        fullscreen:false,
        landscapeEPGExtended:false,
        isBurgerMenuExtended:false,
        contentDetail:undefined
      };
    }
    // eslint-disable-next-line no-underscore-dangle
    const _isMuted=sessionStorage.getItem('isLiveTvMuted')
    this.isMuted=_isMuted?JSON.parse(_isMuted):true;
    this.homeRef=React.createRef();
    this.refHomeLandscape=React.createRef();
    this.refLandscapeParagraph=React.createRef();
    this.refHomeInfo=React.createRef();
    this.refLandscapeHeader=React.createRef();
    this.refLandscapeWrapper=React.createRef();
    this.refHomeInfoChannel=React.createRef();
    this.refHomeInfoChannelP=React.createRef();
    this.refHomeInfoContent=React.createRef();
    this.refHomeInfoContentP=React.createRef();
    this.refHeader=React.createRef();
    this.refChannelBar=React.createRef()
    this.hideTo=undefined;
    this.displayHideUI=this.displayHideUI.bind(this);
    this.changeChannel=this.changeChannel.bind(this);
    this.stopDisplaying=this.stopDisplaying.bind(this);
    this.toggleFullScreen=this.toggleFullScreen.bind(this);
    this.stopHiding=this.stopHiding.bind(this);
    this.showCollapsedMenu=this.showCollapsedMenu.bind(this);
    this.hideUncollapsedMenu=this.hideUncollapsedMenu.bind(this);
    this.addEventListeners=this.addEventListeners.bind(this)
    this.addRotateWarningForFullScreen=this.addRotateWarningForFullScreen.bind(this)
    this.carryAssetFromLandscapeToPortrait=this.carryAssetFromLandscapeToPortrait.bind(this)

  }


  componentDidMount() {
    const {
      channels,
      match: {
        params: { partnerId, assetId}
      },
      currentLiveChannel,
      isAd
    } = this.props;

    const { currentFocusChannel } = this.state;
    let channel=currentFocusChannel;
    if (partnerId && Number(partnerId)!==Number(currentLiveChannel.id)) {
      channel=channels[this.findChannelPositionFromID(partnerId)];
    }

    if(!isAd){
      this.changeChannel(channel, 0);
    }
    if(assetId){

      this.contentDetailInfo();

    }

    this.onFullScreenChange();
    if(document.onwebkitfullscreenchange) {
      document.onwebkitfullscreenchange=this.onFullScreenChange;
    }
    document.addEventListener('fullscreenchange',()=>this.onFullScreenChange())
    document.addEventListener('mozfullscreenchange',()=>this.onFullScreenChange())
    document.addEventListener('webkitfullscreenchange',()=>this.onFullScreenChange())



    this.onChannelFocused(channel)
    this.displayHideUI();

    this.addEventListeners();


    const {current}=this.context;
    if(current){
      if(this.isMuted){current.mute()}else{current.unmute()}
      if(current.isPaused){
        current.play()
      }
    }

    this.updateInterval = setInterval(() => {
      // eslint-disable-next-line no-shadow
      const{currentFocusChannel}= this.state
      this.onChannelFocused(currentFocusChannel);
    }, 30 * 1000);

  }

  componentDidUpdate(prevProps) {
    const {match: {
      params: { partnerId }
    }
    } = this.props;


    if((!Number(prevProps.match.params.partnerId) && Number(partnerId))
      || (prevProps.match.params.partnerId && Number(prevProps.match.params.partnerId) !== Number(partnerId) )){
      const {channels}=this.props;
      const channel=channels[this.findChannelPositionFromID(partnerId)];
      this.changeChannel(channel,0)
    }




  }

  componentWillUnmount() {
    const {extended}=this.props;
    const {current} = this.context;
    if (current) {
      sessionStorage.setItem('isLiveTvMuted', JSON.stringify(current.isMuted()))
    }
    const { currentFocusChannel,
      currentAsset,
      channelPressed,
      showHint,
      UIActive,
      fullscreen,
      landscapeEPGExtended,
    }=this.state;


    const item={ currentFocusChannel,
      currentAsset,
      channelPressed,
      showHint,
      UIActive,
      fullscreen,
      landscapeEPGExtended,
      isBurgerMenuExtended:extended===1,
      contentDetail:undefined}
    localStorage.setItem(Home.identifier, JSON.stringify(item));

    this.removeEventListeners();
    clearInterval(this.updateInterval);
    clearTimeout(this.displayingHidingTo)
    clearTimeout(this.hideTo);
    clearTimeout(this.displayUITO)
    clearTimeout(this.channelChangeTO)







  }


  onChannelFocused = (channel, changeDelay = 1000, changeChannel = false) => {
    const { epg } = this.props;
    const channelEpgId =video.getEPGIdFromChannel(channel);
    // todo: check which property of channel shall be used as "title" comes with spacing
    video
      .getPlayingVideoFromChannel(epg, channelEpgId)
      .then(asset => {
        this.setState({ currentFocusChannel: channel, currentAsset: asset }, () => {
          if (changeChannel) {
            if (this.preloaded) {
              this.preloaded = false;
              this.changeChannel(channel, 0);
            } else {
              this.changeChannel(channel, changeDelay);
            }
          }
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.warn(error);
      });
  };

  // eslint-disable-next-line react/sort-comp
  changeIsMuted=(bool)=>{
    this.isMuted=bool;
  }

  getChannelInfo(){
    const{epg,currentLiveChannel}=this.props
    return epg[`${currentLiveChannel.epgId}`]
  }

  carryAssetFromLandscapeToPortrait(item){
    this.setState({contentDetail:item})
  }


  resetCurrentFocusedChannel = () => {
    const { channels, currentLiveChannel } = this.props;
    const position = this.findChannelPositionFromID(currentLiveChannel.id);
    this.onChannelFocused(channels[position]);
  };

  findChannelPositionFromID = id => {
    const { channels } = this.props;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < channels.length; i++) {
      const channel = channels[i];
      // eslint-disable-next-line eqeqeq
      if (channel.id == id) return i;
    }

    return 0;
  };


  changeChannel = (channel, changeDelay = 1000) => {
    const {currentFocusChannel}=this.state;

    const { isPlaying } = this.props;
    if (this.channelChangeTO) {
      clearTimeout(this.channelChangeTO);
      this.channelChangeTO = undefined;
    }

    const channelEpgId =video.getEPGIdFromChannel(channel);
    if (channel &&
      // eslint-disable-next-line eqeqeq
      (!currentFocusChannel || currentFocusChannel.id != channel.id || changeDelay <= 0)) {
      this.channelChangeTO = setTimeout(() => {
        trackPageView(PAGES.LIVE_PLAYER, channel.title);
        store.dispatch(
          appActions.updateCurrentPlayingVideoFromChannel(channelEpgId, true,
            playingAsset => {
              let currentAsset=playingAsset
              const{epg}=this.props;
              const epgId=video.getEPGIdFromChannel(channel)
              video.getPlayingVideoFromChannel(epg,epgId).then(asset=>{
                currentAsset=asset}).then(()=>{
                this.setState({currentFocusChannel:channel,currentAsset},()=>{
                  const {current}=this.context;
                  if (channel.displayInLiveTV) {
                    current.setStartTime(video.liveVideoTotalProgress(playingAsset.startUnix));
                    initLiveAds(getPartnerIdentifierFromChannel(channel));
                  } else {
                    current.setStartTime(0);
                    stopLiveAds();
                  }
                  localStorage.setItem(Home.identifier, JSON.stringify(this.state));
                  this.changing = false;
                })
              }).catch(error => {
                // eslint-disable-next-line no-console
                console.error('The video from that channel is not loaded>>', error);
                history.push({ pathname: '/error/501', state: { clearStorage: true } });
                throw error;
              });
            })
        );
        this.channelChangeTO = undefined;
      }, changeDelay);

    }

    if (!areAdsInitialized() && isPlaying) {
      initLiveAds(getPartnerIdentifierFromChannel(channel));
    }

  };

  addEventListeners(){
    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const unload = isOnIOS ? "pagehide" : "beforeunload";

    window.addEventListener(unload,()=>{
      this.setState({contentDetail:undefined},()=>{
        localStorage.setItem(Home.identifier, JSON.stringify(this.state));
      })

    })

    document.addEventListener('keydown',()=>{
      const{UIActive}=this.state;
      if(UIActive){
        this.hideUI();
      }else{
        this.displayHideUI();
      }
    })


    document.addEventListener('mousemove',()=> {
      const { landscapeEPGExtended,isBurgerMenuExtended}=this.state;
      const { deviceType}=this.props;
      if ((!landscapeEPGExtended&& deviceType!=="desktop" && isPortrait()==='landscape')||
        (deviceType!=="desktop"&&isPortrait()==='portrait'&& isBurgerMenuExtended!==true)||
        (deviceType==="desktop")) {
        clearTimeout(this.hideTo);
        clearTimeout(this.displayUITO);
        this.displayHideUI()
      }
    })

    document.addEventListener('mousedown',(e)=>{
      const {UIActive,landscapeEPGExtended, isBurgerMenuExtended} = this.state;
      const {deviceType}=this.props;

      if((!landscapeEPGExtended&&deviceType!=="desktop" && isPortrait()==='landscape')||
        (deviceType!=="desktop"&&isPortrait()==='portrait' && isBurgerMenuExtended!==true)||
        (deviceType==="desktop")){
        if (UIActive && this.checkClickedElement(e)===false) {
          this.hideUI()
        } else if(UIActive && this.checkClickedElement(e)===true ) {
          this.displayHideUI();
        }else{
          this.displayHideUI();
        }
      }

    })

    if(this.homeRef.current) this.homeRef.current.addEventListener('mouseleave',()=>{
      clearTimeout(this.hideTo)
      clearTimeout(this.displayUITO)
      this.setState({UIActive:false})
    })

  }

  removeEventListeners(){
    document.removeEventListener('keydown', ()=>{
      const{UIActive}=this.state;
      if(UIActive){
        this.hideUI();
      }else{
        this.displayHideUI();
      }
    },false);

    document.removeEventListener('fullscreenchange', this.onFullScreenChange)

    document.removeEventListener('mousemove', ()=> {
      const { landscapeEPGExtended,isBurgerMenuExtended}=this.state;
      const {deviceType}=this.props;
      if ((!landscapeEPGExtended&&deviceType!=="desktop"&& isPortrait()==='landscape')||
        (deviceType!=="desktop"&&isPortrait()==='portrait'&& isBurgerMenuExtended!==true)||
        (deviceType==="desktop")) {
        clearTimeout(this.hideTo);
        clearTimeout(this.displayUITO);
        this.displayHideUI()
      }
    }, false);
    document.removeEventListener('mousedown', (e)=>{
      const {UIActive,landscapeEPGExtended, isBurgerMenuExtended} = this.state;
      const { deviceType}=this.props;
      if((!landscapeEPGExtended&&deviceType!=="desktop" && isPortrait()==='landscape')||
        (deviceType!=="desktop"&&isPortrait()==='portrait' && isBurgerMenuExtended!==true)||
        (deviceType==="desktop")){
        if (UIActive && this.checkClickedElement(e)===false) {
          this.hideUI()
        } else if(UIActive && this.checkClickedElement(e)===true ) {
          this.displayHideUI();
        }else{
          this.displayHideUI();
        }
      }

    }, false);

    this.homeRef.current.removeEventListener('mouseleave',()=>{
      clearTimeout(this.hideTo)
      clearTimeout(this.displayUITO)
      clearTimeout(this.displayingHidingTo)
      this.setState({UIActive:false})
    }, false)
  }

  onFullScreenChange= ()=> {
    // eslint-disable-next-line max-len
    const fullScreenElement = document.fullscreenElement||document.msFullscreenElement||document.mozFullScreenElement || document.webkitFullscreenElement;
    // eslint-disable-next-line no-unneeded-ternary
    this.setState({fullscreen:fullScreenElement?true:false})
  };


  fullScreen(){
    const {currentVideo}=this.props;
    const app=document.getElementById('root');
    if (app.requestFullScreen) {
      app.requestFullScreen();
    }else if (app.webkitEnterFullscreen) {
      app.webkitEnterFullscreen(); // for iphone this code worked
    } else if (app.webkitRequestFullScreen) {
      app.webkitRequestFullScreen();
    } else if (app.mozRequestFullScreen) {
      app.mozRequestFullScreen();
    } else if (app.msRequestFullscreen) {
      app.msRequestFullscreen();
    }

    trackVideoEvent(EVENTS.VIDEO.ACTIONS.FULLSCREEN,currentVideo)

  }


  toggleFullScreen(){
    const {currentVideo}=this.props;
    if(document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement){
      this.setState({fullscreen:false},()=>{
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullScreen) { /* Safari */
          document.webkitExitFullScreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }else if(document.mozCancelFullScreen){
          document.mozCancelFullScreen();
        }
        else{
          document.requestFullScreen();
        }
        trackVideoEvent(EVENTS.VIDEO.ACTIONS.CANCEL_FULLSCREEN,currentVideo)
      })

    }

    if(!document.fullscreenElement &&
      !document.msFullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement){
      this.setState({fullscreen:true},()=>{
        this.fullScreen()
      })
    }
  }



  checkClickedElement(e) {
    if ((this.homeRef.current && e.target === this.homeRef.current) ||
      (this.refHomeLandscape.current && e.target === this.refHomeLandscape.current) ||
      (this.refLandscapeParagraph.current && e.target === this.refLandscapeParagraph.current) ||
      (this.refHomeInfo.current && e.target === this.refHomeInfo.current) ||
      (this.refLandscapeHeader.current && e.target === this.refLandscapeHeader.current) ||
      (this.refLandscapeWrapper.current && e.target === this.refLandscapeWrapper.current) ||
      (this.refHeader.current && e.target === this.refHeader.current) ||
      (this.refHomeInfoChannel.current && e.target === this.refHomeInfoChannel.current) ||
      (this.refHomeInfoChannelP.current && e.target === this.refHomeInfoChannelP.current) ||
      (this.refHomeInfoContent.current && e.target === this.refHomeInfoContent.current) ||
      (this.refHomeInfoContentP.current && e.target === this.refHomeInfoContentP.current)
    ) {
      return false;
    }
    return true;
  }



  hideUI(){
    const{UIActive}=this.state;
    if(UIActive) {
      if(this.hideTo) clearTimeout(this.hideTo)
      if(this.hideTo) clearTimeout(this.displayUITO)
      this.hideUITo=setTimeout(()=>{
        this.setState({UIActive:false})
      },10)

    }
  }

  stopHiding(){
    clearTimeout(this.hideTo)
    clearTimeout((this.displayUITO))
    if(this.displayingHidingTo) clearTimeout(this.displayingHidingTo)
    this.displayingHidingTo=setTimeout(()=>{
      this.setState({UIActive:true,landscapeEPGExtended:true})
    }, 50)

  }

  stopDisplaying(){
    clearTimeout(this.hideTo)
    clearTimeout((this.displayUITO))
    if(this.displayingHidingTo) clearTimeout(this.displayingHidingTo)
    this.displayingHidingTo=setTimeout(()=>{
      this.setState({ landscapeEPGExtended:false},()=>{
        this.displayHideUI()
      })
    }, 50)
  }

  displayHideUI(){
    if(this.homeRef.current||this.refHomeLandscape.current) {
      if (this.hideTo) clearTimeout(this.hideTo)
      if (this.displayUITO) clearTimeout(this.displayUITO)
      this.displayUITO = setTimeout(() => {
        this.setState({UIActive: true}, () => {
          this.hideTo = setTimeout(() => {
            this.setState({UIActive: false})
          }, DISPLAY_HIDE_UI_DURATION)
        })
      }, 10)


    }

    if(this.homeRef.current) this.homeRef.current.zIndex = 200;

  }




  showCollapsedMenu () {
    if(this.homeRef.current){
      this.setState({isBurgerMenuExtended:true},()=>{
        clearTimeout(this.displayingHidingTo)
        clearTimeout(this.hideTo);
        clearTimeout(this.displayUITO)
        this.setState({UIActive:true},()=>{
          this.removeEventListeners()
          this.homeRef.current.style.zIndex=5001;
        })
      })
    }
  }

  hideUncollapsedMenu(){
    this.setState({isBurgerMenuExtended:false},()=>{
      clearTimeout(this.displayingHidingTo)
      clearTimeout(this.hideTo);
      clearTimeout(this.displayUITO)
      this.setState({UIActive:false},()=>{
        this.addEventListeners()
        this.displayHideUI()
      })

    })

  }

  addRotateWarningForFullScreen(state){
    this.setState({fullscreen:state})
  }

  isFullScreen(){
    const {fullscreen}=this.state;
    if(fullscreen) return true;
    return false;
  }

  onCSSTransitionEnter = () => {
    if(this.homeRef.current) this.homeRef.current.style.display="block"
  };

  onCSSTransitionExited = () => {
    if(this.homeRef.current) this.homeRef.current.style.display="none"
  };

  contentDetailInfo=()=>{
    const {match:{params:{epgId, assetId}}}=this.props;
    const asset={assetId:Number(assetId), partnerId:epgId,assetType: 'epg'};
    this.setState({contentDetail:asset});
  }


  renderSpinner(){
    const{currentLiveChannel,epg,channels}=this.props;
    if (!channels||!currentLiveChannel||!epg) return <Spinner />;
    return null;
  }



  renderRotateWarning(){
    const { deviceType,rotation}=this.props;
    const {contentDetail}=this.state;

    if(this.isFullScreen()&& deviceType!=="desktop" && rotation==='portrait' ){
      return <RotateWarning type='landscape'   addRotateWarningForFullScreen={this.addRotateWarningForFullScreen}/>
    }else if(!this.isFullScreen() && deviceType!=="desktop"  && rotation==='landscape' && contentDetail!==undefined){
      return <RotateWarning type='portrait'/>
    }
    return null;
  }

  renderBufferingSpinner(){
    const {isBuffering, deviceType}=this.props;
    const {UIActive}=this.state;

    if(isBuffering && !UIActive){
      const spinnerOptions = {
        loop: true,
        autoplay: true,
        animationData:spinnerData,
      };
      return (
        <div className={`loading-view ${deviceType!=="desktop" && 'mobile'} ${isPortrait()==="landscape" && "landscape"}`}>
          <Lottie
            options={spinnerOptions}
            width={deviceType==="desktop"?125:60}
          />
        </div>
      )
    }

    return null;
  }


  render() {
    const {UIActive,currentFocusChannel, currentAsset,contentDetail, fullscreen } = this.state;
    const { isPlaying,isAd,currentLiveChannel,screenSize,epg,isBuffering,isLoading, adRemaining, adTotal,currentVideo, deviceType}= this.props
    return(
      <div id="homeWrapper" className={`home-wrapper ${deviceType!=="desktop" && "mobile"}`}>
        {this.renderRotateWarning() }
        {this.renderBufferingSpinner()}
        {currentFocusChannel && deviceType!=="desktop" && isPortrait()==='portrait' && <div className="blur-down"/>}
        {(deviceType!=="desktop"||screenSize<mobileSize) && isPortrait()==="landscape" && !contentDetail
        && currentLiveChannel && currentAsset && currentFocusChannel &&
        <HomeLandscape
          ref={this.refHomeLandscape}
          refLandscapeHeader={this.refLandscapeHeader}
          refLandscapeWrapper={this.refLandscapeWrapper}
          refParagraph={this.refLandscapeParagraph}
          stopHiding={this.stopHiding}
          stopDisplaying={this.stopDisplaying}
          channelTitle={currentFocusChannel.title}
          currentAsset={currentAsset}
          isAd={isAd}
          id={`${currentFocusChannel.id}`}
          liveChannelId={currentLiveChannel.id}
          UIActive={UIActive}
          currentLiveChannel={currentLiveChannel}
          currentFocusChannel={currentFocusChannel}
          changeChannel={this.onChannelFocused}
          screenSize={screenSize}
          isBuffering={isBuffering}
          isLoading={isLoading}
          adRemaining={adRemaining}
          timeShifting={currentFocusChannel.timeShifting}
          changeIsMuted={this.changeIsMuted}
          isMuted={this.isMuted}
          adTotal={adTotal}
          carryAssetFromLandscapeToPortrait={this.carryAssetFromLandscapeToPortrait}
          currentVideo={currentVideo}
          addEventListeners={this.addEventListeners}
          displayHideUI={this.displayHideUI}
          addRotateWarningForFullScreen={this.addRotateWarningForFullScreen}
          deviceType={deviceType}
        />
        }

        {isAd && (isPortrait()==='portrait'||deviceType==="desktop") &&
        <AdCounterRing adRemaining={adRemaining}
                       adTotal={adTotal}
                       UIActive={UIActive}
                       screenSize={screenSize}
                       deviceType={deviceType}/>}

        {deviceType==="desktop" && !UIActive&& isAd && <div className="top-ad-blur"> </div>}

        {(( (deviceType!=="desktop"||screenSize<mobileSize) && isPortrait()==='portrait') || (deviceType==="desktop"))  &&
        <CSSTransition in={UIActive} appear timeout={250}
                       onEnter={()=>this.onCSSTransitionEnter()}
                       onExited={()=>this.onCSSTransitionExited()}
                       classNames="home-transition">
          <div id="home" ref={this.homeRef} className={`${(deviceType!=="desktop"||screenSize<mobileSize) &&'mobile'}`}>
            <div className="home-header">
              {deviceType && <Header ref={this.refHeader}
                      screenSize={screenSize}
                      deviceType={deviceType}
                      UIActive={UIActive}
                      isBuffering={isBuffering}
                      hideUncollapsedMenu={this.hideUncollapsedMenu}
                      showCollapsedMenu={this.showCollapsedMenu}
              />}
            </div>
            { currentAsset  && currentFocusChannel &&
            (((deviceType!=="desktop"||screenSize<mobileSize) && isPortrait()==='portrait')||screenSize>=mobileSize) &&
            (
              <HomeInfo
                infoRef={this.refHomeInfo}
                infoChannelRef={this.refHomeInfoChannel}
                infoChannelPRef={this.refHomeInfoChannelP}
                infoContentRef={this.refHomeInfoContent}
                infoContentPRef={this.refHomeInfoContentP}
                id={`${currentFocusChannel.id}`}
                channelTitle={currentFocusChannel.title}
                data={currentAsset}
                liveChannelId={currentLiveChannel.id}
                liveChannelEpgId={currentLiveChannel.epgId}
                isPlaying={isPlaying}
                isAd={isAd}
                changeIsMuted={this.changeIsMuted}
                isMuted={this.isMuted}
                channelTags={currentFocusChannel.tags}
                channelColor={currentFocusChannel.primary_color}
                timeShifting={currentFocusChannel.timeShifting}
                screenSize={screenSize}
                deviceType={deviceType}
                currentVideo={currentVideo}
                toggleFullScreen={this.toggleFullScreen}
                fullscreen={fullscreen}

              />

            )
            }

            { screenSize>=mobileSize && deviceType==="desktop" && (
              <div className="channel-bar">
                <ChannelBarSlider
                  refChannelBar={this.refChannelBar}
                  onFocusedChannel={this.onChannelFocused}
                  selectChannel={this.changeChannel}
                  displayUI={this.displayHideUI}
                  resetFocusedChannel={this.resetCurrentFocusedChannel}
                  currentFocusedChannel={currentFocusChannel}
                  findChannelPositionFromID={this.findChannelPositionFromID}

                />
              </div>
            )
            }

            {currentFocusChannel&& (deviceType!=="desktop"||screenSize<mobileSize) && (
              <div className="blurs">
                <div className="blur-up"/>
                <div className="blur-down"/>
              </div>
            )
            }
          </div>
        </CSSTransition>
        }

        {currentFocusChannel&& epg && currentLiveChannel && epg
        &&  (deviceType!=="desktop" || screenSize<mobileSize)
        && (isPortrait()==='portrait') &&
        (  <>
          <ProgramGuideView
            changeChannel={this.onChannelFocused}
            currentFocusedChannel={currentFocusChannel}
            assetFromLandscape={contentDetail}
            carryAssetFromLandscapeToPortrait={this.carryAssetFromLandscapeToPortrait}
          />
          <TabBar deviceType={deviceType}/>

        </>)
        }

        { deviceType==="desktop"&&isAd&&!UIActive &&
        (<CurrentVideoFollow  type={1} currentVideo={currentVideo} screenSize={screenSize} UIActive={UIActive}  />)
        }
        {deviceType==="desktop"&&!UIActive&&isAd && (<div className="bottom-ad-blur"/>)}
      </div>
    )}

}




Home.propTypes={
  match:PropTypes.object.isRequired,
  channels: PropTypes.array,
  epg:PropTypes.object,
  currentLiveChannel:PropTypes.object,
  screenSize:PropTypes.number.isRequired,
  isPlaying:PropTypes.bool,
  isBuffering:PropTypes.bool,
  isAd:PropTypes.bool,
  adRemaining:PropTypes.number,
  currentVideo:PropTypes.object,
  adTotal:PropTypes.number
}

Home.defaultProps={
  channels:undefined,
  epg:undefined,
  currentLiveChannel:undefined,
  isPlaying:true,
  isBuffering:undefined,
  isAd:false,
  adRemaining:undefined,
  currentVideo:undefined,
  adTotal:undefined
}

const mapStateToProps = state => ({
  channels: state.app.liveChannels.filter(ch=>ch.displayInLiveTV !== false && ch.teaserType !=='hidden'),
  epg: state.app.epg,
  currentLiveChannel: state.app.currentChannel,
  isPlaying: state.player.isPlaying,
  isBuffering: state.player.isBuffering,
  isLoading: state.player.isLoading,
  isAd: state.player.isAd,
  liveChannels:state.app.liveChannels,
  screenSize:state.app.screenSize,
  vod:state.app.vod,
  adRemaining: state.player.adRemaining,
  adTotal: state.player.adTotal,
  currentVideo: state.player.currentVideo,
  extended: state.app.extended,
  deviceType: state.app.deviceType,
  rotation:state.app.rotation

})

Home.contextType = PlayerContext;

export default connect(mapStateToProps, null, null, { forwardRef: true })(Home);
