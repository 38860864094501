import API from '../instances/sportradar_instance';
import { appendSRParams } from '../../storage/sr-properties';

export default {
  /**
   * Get the application config
   * @return {AxiosPromise<any>}
   */

  getBigScreenConfig() {
    return API().get('bigscreen-config?filterPortals=true');
  },
  /**
   * Get the available epg channels
   * @return {AxiosPromise<any>}
   */
  getChannels() {
    return API().get('epg/channels');
  },
  /**
   * Get the content for a channel on a specific day
   * @param channelId
   * @param date YYYY-MM-DD
   * @return {AxiosPromise<any>}
   */
  getChannelEPG(channelId, date) {
    return API().get(`epg/channel/${channelId}?content=true&day=${date}`);
  },

  getAsset(assetId) {
    return API()
      .get(`content/${assetId}`)
      .then(response => API().get(`events/${response.data.data?.event?.id}`))
      .catch(() => API().get(`events/${assetId}`));
  },
  getStreamURL(assetId) {
    return API().post(`content/${assetId}/access/hls`);
  },
  getPlayerSettings(assetId) {
    return API().get(`content/${assetId}/player-setting`);
  },
  getRelatedElements(assetId, limit = 20) {
    return API().get(`content/${assetId}/related-content?limit=${limit}`);
  },
  getModuleData(moduleId, config = {}) {
    return API().get(appendSRParams(`module/${moduleId}/content`, config));
  }
};
